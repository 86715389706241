import { Modal, Section } from '@imposium-hub/components';
import { getErrorCopy } from '../../util/dataset';

export const DatasetErrorModal = ({ onClose, errors, rowIndex }) => {
    return (
        <Modal
            onRequestClose={onClose}
            style={{
                width: '500px',
                height: '70%',
                top: '10%',
                left: 'calc((100% - 600px) / 2)'
            }}
            isOpen={true}>
            <Section title={`Row ${rowIndex + 1} Errors`}>
                <ul>
                    {errors &&
                        errors.map((e, i) => {
                            return (
                                <li
                                    key={i}
                                    className='dataset-error'>
                                    {getErrorCopy(e)}
                                </li>
                            );
                        })}
                </ul>
            </Section>
        </Modal>
    );
};
