import * as React from 'react';
import Cut from './Cut';
import AudioOverlay from './AudioOverlay';
import Base from './Base';
import Playhead from './Playhead';
import ScaleBar from './ScaleBar';
import Labels from './Labels';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { updateEditorConfig } from '../../redux/actions/editor';
import { FRAME_WIDTH, ROW_HEIGHT, FRAMES_PER_SCROLL } from '../../constants/timeline';
import { VIEWER_TYPES } from '../../constants/viewer';
import {
    getMaxOverlays,
    getAudioOverlays,
    getCutIndex,
    getOverlayIndex,
    getCut,
    doesCutFit,
    newCut,
    newAudioOverlay
} from '../../util/story';
import hotkeys from 'hotkeys-js';
import { logError } from '../../util/notifications';
import { timeline as copy } from '../../constants/copy';
import { IEditor } from '../../redux/reducers/editor';
import { IProject } from '../../redux/reducers/project';
import PositionDisplay from './PositionDisplay';
import ChangeBaseVideo from './ChangeBaseVideo';
import { setActiveViewer, addViewer } from '../../redux/actions/story';
import { Button, filterHotkeys } from '@imposium-hub/components';
import { ICON_CUT, ICON_EDIT, ICON_HEADPHONES, ICON_PLAY, ICON_PLUS } from '../../constants/icons';
import { ITimelineState } from '../../redux/reducers/timeline';
import { updateTimelineState } from '../../redux/actions/timeline';

interface ITimelineInternalState {
    width: number;
}

interface ITimelineProps {
    audioOverlays: any;
    cuts: any;
    totalFrames: any;
    baseVideoUrl?: any;
    rate: number;
    width: number;
    height: number;
    editor: IEditor;
    timeline: ITimelineState;
    project: IProject;
    baseVideoUploaded(v: any): void;
    reorderCuts(conf: any): any;
    addViewer(conf: any): any;
    updateCut(conf: any): any;
    deleteCut(conf: any): any;
    addCut(config: any): any;
    addAudioOverlay(config: any): any;
    updateEditorConfig(conf: any): any;
    updateTimelineState(state: any): any;
    setActiveViewer(id: any): any;
    deleteAudioOverlay(conf: any): any;
}

class Timeline extends React.PureComponent<ITimelineProps, ITimelineInternalState> {
    private timelineWrapper: any;

    private evtHandlers: any;

    constructor(props) {
        super(props);

        this.state = {
            width: window.innerWidth
        };

        this.timelineWrapper = React.createRef();

        this.evtHandlers = {
            resize: () => this.resize(),
            jumpToFrame: (e) => this.jumpToFrame(e),
            buttons: {
                newAudio: (e) => this.newAudioOverlay(e),
                playBase: () => this.playBaseVideo(),
                newCut: (e) => this.newCut(e)
            },
            cut: {
                update: (c) => this.updateCut(c),
                move: (c) => this.moveCut(c),
                setActiveFrame: (f) => this.setActiveFrame(f),
                select: (c, o) => this.selectCut(c, o)
            },
            hotkeys: {
                nextFrame: (e) => this.nextFrame(e),
                nextTen: (e) => this.nextFrame(e, true),
                prevTen: (e) => this.previousFrame(e, true),
                prevFrame: (e) => this.previousFrame(e),
                delete: (e) => this.deleteActiveTimelineElement(e),
                setIn: (e) => this.jumpToCutIn(e),
                setOut: (e) => this.jumpToCutOut(e),
                jumpIn: (e) => this.setCutIn(e),
                jumpOut: (e) => this.setCutOut(e)
            },
            updateTimelineScale: (s, p) => this.updateTimeline(s, p),
            baseVideoUploaded: (v) => this.props.baseVideoUploaded(v),
            clearSelected: (e) => this.clearSelected(e),
            playheadMove: (f) => this.playheadMoved(f),
            playheadScroll: (d) => this.playheadScroll(d)
        };

        hotkeys.filter = (e) => filterHotkeys(e);
    }

    public bindHotkeys() {
        hotkeys('shift+i', this.evtHandlers.hotkeys.jumpIn);
        hotkeys('shift+o', this.evtHandlers.hotkeys.jumpOut);
        hotkeys('command+i, control+i', this.evtHandlers.hotkeys.setIn);
        hotkeys('command+o ,control+o', this.evtHandlers.hotkeys.setOut);
        hotkeys('command+b, control+b', this.evtHandlers.buttons.newCut);
        hotkeys('command+right, alt+right', this.evtHandlers.hotkeys.nextFrame);
        hotkeys('command+left, alt+left', this.evtHandlers.hotkeys.prevFrame);
        hotkeys('backspace', this.evtHandlers.hotkeys.delete);
        hotkeys('command+shift+right, alt+shift+right', this.evtHandlers.hotkeys.nextTen);
        hotkeys('command+shift+left, alt+shift+left', this.evtHandlers.hotkeys.prevTen);
    }

    public unbindHotkeys() {
        hotkeys.unbind('shift+i', this.evtHandlers.hotkeys.jumpIn);
        hotkeys.unbind('shift+o', this.evtHandlers.hotkeys.jumpOut);
        hotkeys.unbind('command+i, control+i', this.evtHandlers.hotkeys.setIn);
        hotkeys.unbind('command+o ,control+o', this.evtHandlers.hotkeys.setOut);
        hotkeys.unbind('command+b, control+b', this.evtHandlers.buttons.newCut);
        hotkeys.unbind('command+right, alt+right', this.evtHandlers.hotkeys.nextFrame);
        hotkeys.unbind('command+left, alt+left', this.evtHandlers.hotkeys.prevFrame);
        hotkeys.unbind('backspace', this.evtHandlers.hotkeys.delete);
        hotkeys.unbind('command+shift+right, alt+shift+right', this.evtHandlers.hotkeys.nextTen);
        hotkeys.unbind('command+shift+left, alt+shift+left', this.evtHandlers.hotkeys.prevTen);
    }

    public componentWillUnmount() {
        this.unbindHotkeys();
    }

    public componentDidMount() {
        this.bindHotkeys();

        window.addEventListener('resize', this.evtHandlers.resize, true);
        this.resize();
    }

    private deleteActiveTimelineElement(e) {
        const {
            timeline: { activeTimelineCut, activeTimelineOverlay },
            project: { actId, sceneId },
            audioOverlays,
            cuts
        } = this.props;

        if (activeTimelineOverlay) {
            const c = confirm(copy.confirmOverlayDelete);
            if (c) {
                const activeAudioOverlay: any = audioOverlays.find(
                    (a: any) => a.id === activeTimelineOverlay
                );

                if (activeAudioOverlay) {
                    const audIdx = audioOverlays.indexOf(activeAudioOverlay);
                    this.props.deleteAudioOverlay({ index: audIdx });
                } else {
                    const cutIndex = getCutIndex(cuts, activeTimelineCut);

                    const cut = { ...cuts[cutIndex] };
                    const overlayIndex = getOverlayIndex(cut, activeTimelineOverlay);
                    const overlays = [...cut.overlays];

                    overlays.splice(overlayIndex, 1);
                    cut.overlays = overlays;

                    const updateConfig = {
                        actId,
                        sceneId,
                        cutIndex,
                        cut
                    };

                    this.props.updateCut(updateConfig);
                    this.props.updateTimelineState({ activeTimelineOverlay: null });
                }
            }
        } else if (activeTimelineCut) {
            const cutIndex = getCutIndex(this.props.cuts, activeTimelineCut);
            const c = confirm(copy.confirmCutDelete);
            if (c) {
                const updateConfig = {
                    actId,
                    sceneId,
                    cutIndex
                };
                this.props.deleteCut(updateConfig);
            }
        }
    }

    private newCut(e?) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        const {
            timeline: { activeFrame },
            project: { actId, sceneId, compositionId }
        } = this.props;

        const cut = newCut(activeFrame[compositionId]);

        const addConfig = {
            actId,
            sceneId,
            cut
        };

        this.props.addCut(addConfig);
        this.props.updateTimelineState({ activeTimelineCut: cut.id });
    }

    private newAudioOverlay(e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        const {
            project: { actId, sceneId }
        } = this.props;
        const overlay = newAudioOverlay();

        const addConfig = {
            actId,
            sceneId,
            overlay
        };

        this.props.addAudioOverlay(addConfig);
        this.props.updateTimelineState({
            activeTimelineCut: null,
            activeTimelineOverlay: overlay.id
        });
    }

    public getSingleFrameOffset(): number {
        const { totalFrames } = this.props;
        return 1 / totalFrames;
    }

    public getTimelineHeight(): number {
        const { cuts, audioOverlays } = this.props;
        return ROW_HEIGHT * (2 + getMaxOverlays(cuts) + getAudioOverlays(audioOverlays));
    }

    public getCutHeight(): number {
        const { audioOverlays } = this.props;
        return this.getTimelineHeight() - getAudioOverlays(audioOverlays) * ROW_HEIGHT;
    }

    public getTimelineScale(): number {
        const { width } = this.state;
        const {
            timeline: { scaleModifier },
            project: { compositionId },
            cuts,
            totalFrames
        } = this.props;
        let timelineW;
        const scale = scaleModifier[compositionId] ? scaleModifier[compositionId] : 1;
        if (cuts[cuts.length - 1]?.endFrame > totalFrames) {
            timelineW = FRAME_WIDTH * cuts[cuts.length - 1]?.endFrame;
        } else {
            timelineW = FRAME_WIDTH * totalFrames;
        }
        const screenScale = width / timelineW;
        const timelineScale = screenScale / scale;

        return timelineScale;
    }

    public getTimelineWidth(timelineScale): number {
        const { totalFrames, cuts } = this.props;

        if (cuts[cuts.length - 1]?.endFrame > totalFrames) {
            return FRAME_WIDTH * cuts[cuts.length - 1].endFrame * timelineScale;
        }
        return FRAME_WIDTH * totalFrames * timelineScale;
    }

    public getTimelineX(w): number {
        const {
            timeline: { positionOffset }
        } = this.props;
        return w * -positionOffset;
    }

    public jumpToCutIn(e) {
        const {
            cuts,
            timeline: { activeTimelineCut }
        } = this.props;
        const index = getCutIndex(cuts, activeTimelineCut);
        if (activeTimelineCut) {
            const actFrame = cuts[index].startFrame;
            this.props.updateTimelineState({
                actFrame
            });
        }
    }

    public jumpToCutOut(e) {
        const {
            cuts,
            timeline: { activeTimelineCut }
        } = this.props;
        const index = getCutIndex(cuts, activeTimelineCut);
        if (activeTimelineCut) {
            const activeFrame = cuts[index].endFrame;
            this.props.updateTimelineState({
                activeFrame
            });
        }
    }

    public jumpToFrame(newFrame) {
        const {
            project: { compositionId },
            timeline: { activeFrame }
        } = this.props;
        if (activeFrame[compositionId] !== newFrame) {
            this.props.updateTimelineState({ activeFrame: newFrame });
            this.props.setActiveViewer(VIEWER_TYPES.TIMELINE_PREVIEW);
        }
    }

    private clearSelected(e) {
        e.preventDefault();
        e.stopPropagation();

        this.props.updateTimelineState({
            activeTimelineCut: null,
            activeTimelineOverlay: null
        });
    }

    public moveCut(newPlacement) {
        const {
            timeline: { activeTimelineCut },
            project: { actId, sceneId },
            cuts
        } = this.props;
        const cutIndex = getCutIndex(cuts, activeTimelineCut);
        const cut = Object.assign({}, getCut(cuts, activeTimelineCut), newPlacement);

        if (doesCutFit(cuts, cut)) {
            const updateConfig = {
                actId,
                sceneId,
                cutIndex,
                cut
            };

            this.props.updateCut(updateConfig);
            this.props.reorderCuts(updateConfig);
        } else {
            logError(copy.fitError);
        }
    }

    public nextFrame(e, jump = false) {
        const {
            timeline: { activeFrame },
            project: { compositionId },
            totalFrames
        } = this.props;
        const increment = jump ? 10 : 1;
        const next = activeFrame[compositionId] + increment;

        if (next <= totalFrames) {
            this.props.updateTimelineState({ activeFrame: next });
        }
    }

    public playheadMoved(activeFrame) {
        this.props.updateTimelineState({ activeFrame });
        this.props.setActiveViewer(VIEWER_TYPES.TIMELINE_PREVIEW);
    }

    public playheadScroll(direction) {
        const frameOffset = this.getSingleFrameOffset();
        const {
            totalFrames,
            timeline: { positionOffset, activeFrame, scaleModifier },
            project: { compositionId }
        } = this.props;

        let offset;
        let frame;
        const scale = scaleModifier[compositionId] ? scaleModifier[compositionId] : 1;
        const posOffset = positionOffset[compositionId] ? positionOffset[compositionId] : 0;
        const actFrame = activeFrame[compositionId] ? activeFrame[compositionId] : 0;
        if (direction === 'left') {
            offset = Math.max(posOffset - frameOffset * FRAMES_PER_SCROLL, 0);
            frame = Math.max(actFrame - FRAMES_PER_SCROLL, 0);
        } else if (direction === 'right') {
            offset = Math.min(posOffset + frameOffset * FRAMES_PER_SCROLL, 1 - scale);
            frame = Math.min(actFrame + FRAMES_PER_SCROLL, totalFrames);
        }

        if (offset !== posOffset) {
            const updatePosition = { [compositionId]: offset };
            const updateOffset = { ...positionOffset, ...updatePosition };
            this.props.updateTimelineState({
                positionOffset: updateOffset,
                activeFrame: frame
            });

            this.props.setActiveViewer(VIEWER_TYPES.TIMELINE_PREVIEW);
        }
    }

    public previousFrame(e, jump = false) {
        const {
            timeline: { activeFrame },
            project: { compositionId }
        } = this.props;
        const increment = jump ? 10 : 1;
        const prev = activeFrame[compositionId] - increment;
        if (prev >= 0) {
            this.props.updateTimelineState({ activeFrame: prev });
        }
    }

    public resize() {
        const container = this.timelineWrapper.current;

        if (container) {
            const containerW = container.getBoundingClientRect().width;
            this.setState({
                width: containerW
            });
        }
    }

    public selectCut(cutId: string, overlayId?: string) {
        this.props.updateTimelineState({
            activeTimelineCut: cutId,
            activeTimelineOverlay: overlayId
        });
    }

    public setActiveFrame(frame) {
        this.props.updateTimelineState({
            activeFrame: frame
        });
    }

    public setCutIn(e) {
        e.preventDefault();

        const {
            timeline: { activeTimelineCut, activeFrame },
            cuts
        } = this.props;

        if (activeTimelineCut) {
            const cut = getCut(cuts, activeTimelineCut);

            const cutProxy = {
                id: activeTimelineCut,
                startFrame: activeFrame,
                endFrame: cut.endFrame
            };

            if (cutProxy.startFrame < cutProxy.endFrame) {
                if (doesCutFit(cuts, cutProxy)) {
                    this.updateCut({
                        startFrame: activeFrame
                    });
                } else {
                    logError(copy.startOverlapError);
                }
            } else {
                logError(copy.startEndError);
            }
        }
    }

    public setCutOut(e) {
        e.preventDefault();

        const {
            timeline: { activeTimelineCut, activeFrame },
            cuts
        } = this.props;

        if (activeTimelineCut) {
            const cut = getCut(cuts, activeTimelineCut);

            const cutProxy = {
                id: activeTimelineCut,
                startFrame: cut.startFrame,
                endFrame: activeFrame
            };

            if (cutProxy.endFrame > cutProxy.startFrame) {
                if (doesCutFit(cuts, cutProxy)) {
                    this.updateCut({
                        endFrame: activeFrame
                    });
                } else {
                    logError(copy.endOverlapError);
                }
            } else {
                logError(copy.endStartError);
            }
        }
    }

    public updateCut(c) {
        const {
            timeline: { activeTimelineCut },
            project: { actId, sceneId },
            cuts
        } = this.props;
        const cutIndex = getCutIndex(cuts, activeTimelineCut);
        const cut = Object.assign({}, getCut(cuts, activeTimelineCut), c);

        const updateConfig = {
            actId,
            sceneId,
            cutIndex,
            cut
        };

        this.props.updateCut(updateConfig);
    }

    public updateTimeline(newScale, newOffset) {
        const {
            project: { compositionId },
            timeline: { scaleModifier, positionOffset }
        } = this.props;

        const updateScale = { [compositionId]: newScale };
        const updatePosition = { [compositionId]: newOffset };
        const updateModifier = { ...scaleModifier, ...updateScale };
        const updateOffset = { ...positionOffset, ...updatePosition };
        this.props.updateTimelineState({
            scaleModifier: updateModifier,
            positionOffset: updateOffset
        });
    }

    private playBaseVideo() {
        const {
            baseVideoUrl,
            project: { sceneId }
        } = this.props;

        this.props.addViewer({
            id: sceneId,
            label: copy.baseVideo,
            type: VIEWER_TYPES.BASE_VIDEO,
            url: baseVideoUrl
        });
    }

    public render() {
        const {
            baseVideoUrl,
            cuts,
            totalFrames,
            rate,
            width,
            height,
            timeline: {
                activeFrame,
                activeTimelineCut,
                activeTimelineOverlay,
                positionOffset,
                scaleModifier
            },
            project: { compositionId }
        } = this.props;
        const audioOverlays = this.props.audioOverlays || [];

        if (totalFrames !== undefined) {
            const timelineScale = this.getTimelineScale();
            const w = this.getTimelineWidth(timelineScale);
            const h = this.getTimelineHeight();
            const x = this.getTimelineX(w);

            const timelineStyle = {
                height: `${h + 25 + ROW_HEIGHT}px`
            };

            const innerStyle = {
                width: `${w}px`,
                height: `${h}px`,
                left: `${x}px`
            };

            const controlsStyle = {
                height: `${h + 45}px`
            };

            const scale = scaleModifier[compositionId] ? scaleModifier[compositionId] : 1;
            const offset = positionOffset[compositionId] ? positionOffset[compositionId] : 0;
            const actFrame = activeFrame[compositionId] ? activeFrame[compositionId] : 0;
            return (
                <div className='timeline'>
                    <div
                        className='timeline-controls'
                        style={controlsStyle}>
                        <PositionDisplay
                            activeFrame={actFrame}
                            frameRate={rate}
                        />
                        <div className='audio-buttons'>
                            <Button
                                onClick={this.evtHandlers.buttons.newAudio}
                                tooltip={copy.tooltipAddAudioOverlay}
                                style='subtle'>
                                {ICON_HEADPHONES}
                            </Button>
                        </div>
                        <div className='buttons'>
                            <ChangeBaseVideo
                                icon={baseVideoUrl ? ICON_EDIT : ICON_PLUS}
                                onComplete={this.evtHandlers.baseVideoUploaded}
                            />
                            {baseVideoUrl && (
                                <Button
                                    onClick={this.evtHandlers.buttons.playBase}
                                    tooltip={copy.tooltipPlayBase}
                                    style='subtle'>
                                    {ICON_PLAY}
                                </Button>
                            )}
                            <Button
                                onClick={this.evtHandlers.buttons.newCut}
                                tooltip={copy.tooltipAddCut}
                                style='subtle'>
                                {ICON_CUT}
                            </Button>
                        </div>
                    </div>

                    <ScaleBar
                        frames={totalFrames}
                        activeFrame={actFrame}
                        scale={scale}
                        width={this.state.width}
                        offset={offset}
                        onUpdate={this.evtHandlers.updateTimelineScale}
                        minScale={0.1}
                    />

                    <div
                        className='inner-timeline'
                        ref={this.timelineWrapper}
                        style={timelineStyle}>
                        <Labels
                            activeFrame={actFrame}
                            jumpToFrame={this.evtHandlers.jumpToFrame}
                            frames={totalFrames}
                            rate={rate}
                            scale={timelineScale}
                            width={w}
                            height={h}
                            x={x}
                        />

                        <div
                            className='scaled-timeline'
                            style={innerStyle}>
                            <div
                                className='timeline-bg'
                                onClick={this.evtHandlers.clearSelected}
                            />

                            <Base width={FRAME_WIDTH * totalFrames * timelineScale} />

                            {cuts.map((cut, i) => {
                                const active = cut.id === activeTimelineCut;
                                return (
                                    <Cut
                                        active={active}
                                        activeOverlay={activeTimelineOverlay}
                                        activeFrame={activeFrame}
                                        config={cut}
                                        nextCut={cuts[i + 1]}
                                        previousCut={cuts[i - 1]}
                                        timelineWidth={w}
                                        height={this.getCutHeight()}
                                        key={cut.id}
                                        videoWidth={width}
                                        videoHeight={height}
                                        update={this.evtHandlers.cut.update}
                                        move={this.evtHandlers.cut.move}
                                        setActiveFrame={this.evtHandlers.cut.setActiveFrame}
                                        select={this.evtHandlers.cut.select}
                                        scale={timelineScale}
                                    />
                                );
                            })}

                            {audioOverlays.map((audio, j) => {
                                const active = audio.id === activeTimelineOverlay;

                                return (
                                    <AudioOverlay
                                        active={active}
                                        config={audio}
                                        frames={totalFrames}
                                        key={audio.id}
                                        index={j}
                                        total={audioOverlays.length}
                                        rate={rate}
                                        scale={timelineScale}
                                    />
                                );
                            })}

                            <Playhead
                                frames={totalFrames}
                                activeFrame={actFrame}
                                onMove={this.evtHandlers.playheadMove}
                                width={width}
                                height={h}
                                positionOffset={offset}
                                scale={timelineScale}
                            />
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}

const mapDispatchToProps = (dispatch): any => {
    return bindActionCreators(
        { updateEditorConfig, updateTimelineState, setActiveViewer, addViewer },
        dispatch
    );
};

const mapStateToProps = (state): any => {
    return { editor: state.editor, project: state.project, timeline: state.timeline };
};

export default connect(mapStateToProps, mapDispatchToProps)(Timeline);
