import * as React from 'react';
import { LAYER_SOURCE_STATUSES } from '../../../constants/editor';
import { TEMPLATE_PREVIEW_EVENTS } from '../../../constants/story';
import MediaLayerSource from './MediaLayerSource';
import { parseInventoryFromVariables } from '../../../util/story';
import { api } from '../../../constants/app';

export default class TemplateLayerSource extends MediaLayerSource {
    private imageSprite: any;

    private iframeRef: any;

    private renderCanvas: any;

    private reloadDebounce: any;

    private onMessageHandler = (e) => this.onMessage(e);

    public constructor(props) {
        super(props);

        this.iframeRef = React.createRef();

        this.renderCanvas = document.createElement('canvas');
    }

    // Unique componentDidUpdate function for this specific Source.
    public componentDidUpdate(prevProps, prevState) {
        const {
            layerData,
            layerData: {
                matte_layer_id,
                end_frame,
                video_enabled,
                start_frame,
                options,
                locked,
                options: { width, height, animated, background_color }
            },
            assets,
            variables,
            variableMap,
            playing,
            relativeFrame,
            compWidth,
            compHeight,
            interimPosition,
            mounted,
            zIndex,
            inView,
            frameRate,
            isMatte
        } = this.props;

        const { status } = this.state;

        if (!inView) {
            return;
        }

        const currentDuration = end_frame - start_frame;
        const previousDuration = prevProps?.layerData.end_frame - prevProps?.layerData.start_frame;

        const checkVisibility = () => {
            if (!isMatte && video_enabled) {
                this.addContent();
            } else if (isMatte || !video_enabled) {
                this.removeContent();
            }
        };

        // If the component was mounted, and it should no longer be, unmount it
        if (prevProps.mounted && !mounted) {
            void this.unmount();

            // If the component was not mounted, and it should be, mount it
        } else if (!prevProps.mounted && mounted) {
            void this.mount();
        }

        if (video_enabled !== prevProps.layerData?.video_enabled || isMatte !== prevProps.isMatte) {
            checkVisibility();
        }

        if (prevProps.layerData?.matte_layer_id && !matte_layer_id) {
            if (!matte_layer_id) {
                this.clearMatte();
            }
        }

        if (zIndex !== prevProps.zIndex) {
            this.setZIndex();
        }

        if (locked !== prevProps.layerData?.locked) {
            this.setInteractivity();
        }

        if (playing && !prevProps.playing) {
            this.play();
        } else if (!playing && prevProps.playing) {
            this.pause();
        }

        // If the assets, variables, or layerData.type or layerData.options.source has changed, check for a new layer source
        if (
            layerData.type !== prevProps.layerData.type ||
            layerData?.options?.source !== prevProps.layerData?.options?.source ||
            assets !== prevProps.assets ||
            variables !== prevProps.variables ||
            variableMap !== prevProps.variableMap
        ) {
            const forceUseLocalAssets = assets !== prevProps.assets;
            this.checkForNewLayerSource(forceUseLocalAssets);
        }

        if (
            status !== prevState.status ||
            options?.source !== prevProps?.layerData?.options?.source
        ) {
            switch (status) {
                case LAYER_SOURCE_STATUSES.ERROR:
                case LAYER_SOURCE_STATUSES.NO_SOURCE:
                case LAYER_SOURCE_STATUSES.NO_PREVIEW:
                case LAYER_SOURCE_STATUSES.LOADING:
                    this.clear();
                    this.createPlaceholder();
                    break;
            }
            // If the interim position changed, just resize the layer
        } else if (
            (interimPosition && interimPosition !== prevProps.interimPosition) ||
            compWidth !== prevProps.compWidth ||
            prevProps.compHeight !== compHeight
        ) {
            this.resize();

            // If the keyframe config, effects, position inputs, or relative frame changed, do a full draw
        } else if (
            layerData.keyframes !== prevProps.layerData.keyframes ||
            layerData.effects !== prevProps.layerData.effects ||
            layerData.position_inputs !== prevProps.layerData.position_inputs ||
            relativeFrame !== prevProps.relativeFrame
        ) {
            // Only pass in seek=true if the relativeFrame changed
            void this.draw(relativeFrame !== prevProps.relativeFrame);
        }

        if (
            frameRate !== prevProps.frameRate ||
            currentDuration !== previousDuration ||
            background_color !== prevProps.layerData.options.background_color ||
            width !== prevProps.layerData.options.width ||
            height !== prevProps.layerData.options.height ||
            animated !== prevProps.layerData.options.animated ||
            variables !== prevProps.variables ||
            variableMap !== prevProps.variableMap
        ) {
            clearTimeout(this.reloadDebounce);
            this.reloadDebounce = setTimeout(() => this.reloadLayerSource(), 250);
        }
    }

    private reloadLayerSource() {
        this.clear();
        this.loadLayerSource().catch((e) => {
            this.logError(`Error loading layer source`, e);
            this.setState({
                status: LAYER_SOURCE_STATUSES.ERROR
            });
        });
    }

    private async getDevConfig() {
        const inventory = parseInventoryFromVariables(this.props.variables, this.props.variableMap);
        const {
            frameRate,
            relativeFrame,
            layerData: {
                end_frame,
                start_frame,
                options: { background_color, animated, width, height }
            },
            storyId
        } = this.props;

        const map = await api.getAssetMap(storyId);
        const devConfig = {
            assets: JSON.stringify(map.assets),
            width,
            height,
            frames: end_frame - start_frame,
            frame: relativeFrame,
            fps: frameRate,
            type: animated ? 'image_sequence' : 'image',
            background_color,
            inventory
        };

        return devConfig;
    }

    public clear(): void {
        super.clear();

        // TODO: destroy all the things
    }

    public seek() {
        super.seek();

        if (!this.mediaSprite) {
            return;
        }

        if (!this.state.capturingScreenshot && this.state.status === LAYER_SOURCE_STATUSES.READY) {
            this.setState(
                {
                    capturingScreenshot: true
                },
                () => {
                    const frame = Math.max(this.props.relativeFrame, 0);
                    this.iframeRef.current.contentWindow.postMessage(
                        {
                            type: 'CAPTURE_SCREENSHOT',
                            frame
                        },
                        '*'
                    );
                }
            );
        }
    }

    public componentDidMount() {
        super.componentDidMount();
        window.addEventListener('message', this.onMessageHandler);
    }

    public componentWillUnmount(): void {
        clearTimeout(this.reloadDebounce);
        window.removeEventListener('message', this.onMessageHandler);

        super.componentWillUnmount();
    }

    private onMessage(e) {
        if (e && e.data && e.data.type && e.data.layerId === this.props.layerData.id) {
            if (e.data.type === TEMPLATE_PREVIEW_EVENTS.CAPTURE_READY) {
                this.captureReady(e.data.frame, e.data.capture);
            } else if (e.data.type === TEMPLATE_PREVIEW_EVENTS.PRE_INIT) {
                void this.setDevConfig();
            }
        }
    }

    private async setDevConfig() {
        const config = await this.getDevConfig();
        this.iframeRef.current.contentWindow.postMessage(
            {
                type: 'SET_DEV_CONFIG',
                config
            },
            '*'
        );
    }

    private captureReady = (frame, capture) => {
        if (this.imageSprite) {
            this.mediaSprite.removeChild(this.imageSprite);
            this.imageSprite.destroy(true);
            this.imageSprite = null;
        }

        const pixels = new ImageData(
            new Uint8ClampedArray(capture.pixels),
            capture.width,
            capture.height
        );

        const ctx = this.renderCanvas.getContext('2d');
        ctx.putImageData(pixels, 0, 0);

        this.mediaSprite.texture.update();

        this.setState({
            capturingScreenshot: false
        });
    };

    public async loadLayerSource(): Promise<void | any> {
        const templateWidth = this.props.layerData.options.width;
        const templateHeight = this.props.layerData.options.height;

        this.renderCanvas.width = templateWidth;
        this.renderCanvas.height = templateHeight;

        this.logInfo(`Load Layer Source`);

        this.setState({
            status: LAYER_SOURCE_STATUSES.LOADING
        });

        const timer = Date.now();

        return new Promise<void>((resolve, reject) => {
            const onLoadMessage = (e) => {
                if (e && e.data && e.data.type && e.data.layerId === this.props.layerData.id) {
                    if (e.data.type === TEMPLATE_PREVIEW_EVENTS.LOADED) {
                        const sourceTimer = Date.now() - timer;
                        this.logInfo(`Layer source loaded in ${sourceTimer}ms`);
                        window.removeEventListener('message', onLoadMessage);
                        this.createMediaSprite(this.renderCanvas);
                        this.setState(
                            {
                                status: LAYER_SOURCE_STATUSES.READY
                            },
                            () => {
                                void this.seek();
                            }
                        );
                        resolve();
                    }
                }
            };

            window.addEventListener('message', onLoadMessage);

            this.iframeRef.current.width = templateWidth;
            this.iframeRef.current.height = templateHeight;

            // Make sure the preview URL is an .html URL, and not a .zip URL
            if (
                this.props.source.preview_url &&
                this.props.source.preview_url.indexOf('html') !== -1
            ) {
                this.iframeRef.current.src = `${this.props.source.preview_url}?embed=true&layerId=${this.props.layerData.id}`;
            } else {
                reject();
            }
        });
    }

    public render() {
        const iframeStyle = {
            transform: 'translateX(10000px)'
        };

        return (
            <iframe
                style={iframeStyle}
                ref={this.iframeRef}></iframe>
        );
    }
}
