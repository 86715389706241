import {
    Button,
    ButtonMenu,
    ButtonMenuItem,
    cancelAssetUpload,
    deleteAssets,
    doAssetTableHydration,
    IImposiumAPI,
    updateAssetStory
} from '@imposium-hub/components';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { api } from '../constants/app';
import { assets, fields as copy, timeline, copyAsset } from '../constants/copy';
import {
    ICON_BAN,
    ICON_COG,
    ICON_COPY,
    ICON_CROSSHAIRS,
    ICON_DOWNLOAD,
    ICON_ELLIPSIS_H,
    ICON_FILE_PEN,
    ICON_GLOBE,
    ICON_LAYER_GROUP,
    ICON_LINK,
    ICON_LIST,
    ICON_TIMES,
    ICON_TRASH
} from '../constants/icons';
import { IComposition, IStory } from '../constants/snippets';
import { ASSET_TYPES } from '../constants/story';
import { createComposition } from '../redux/actions/compositions';
import { replaceViewer, closeViewer } from '../redux/actions/story';
import { logError, logNotification } from '../util/notifications';
import { getCompFromAsset } from '../util/story';
import { IEditor } from '../redux/reducers/editor';
import { RESERVED_COMP_RENDER_NAME } from '../constants/editor';

interface IAssetContextMenuProps {
    story: IStory;
    editor: IEditor;
    createComposition(id, comp): any;
    replaceViewer(config): void;
    doAssetTableHydration(apiInstance: IImposiumAPI, sId: string, callback?: any): void;
    deleteAssets: (apiInstance: IImposiumAPI, ids: string[], storyId: string) => any;
    updateAssetStory: (apiInstance: IImposiumAPI, id: string, sId: string) => any;
    asset: any;
    showDelete: boolean;
    showClose: boolean;
    showDetails: boolean;
    showSettings: boolean;
    onClose?(): any;
    onSettings?(): any;
    onDetails?(): any;
    position: string;
    closeViewer(id): void;
    cancelAssetUpload: (fileName: string, apiInstance: IImposiumAPI, assetId: string) => any;
    setAssetId(id, type): void;
    isViewer: boolean;
}

class AssetContextMenu extends React.PureComponent<IAssetContextMenuProps> {
    private evtHandlers: any;

    constructor(props) {
        super(props);

        this.evtHandlers = {
            onCopyUrl: () => this.copyAssetUrl(),
            createCompFromVideo: () => this.createCompFromVideo(),
            onDuplicate: () => this.duplicateAsset(),
            onDelete: () => this.deleteAsset(),
            onDecouple: () => this.decoupleAsset(),
            onCouple: () => this.coupleAsset(),
            onCopyId: () => this.copyAssetId(),
            onCancel: () => this.cancelAssetUpload(),
            onReplace: () => this.replaceAssetHandler(),
            onDownloadJSON: () => this.downloadJSONHandler()
        };
    }

    private copyAssetUrl() {
        navigator.clipboard.writeText(this.props.asset?.edited_url).then(
            () => {
                logNotification(copyAsset.copied);
            },
            () => {
                logError(copyAsset.error);
            }
        );
    }

    private copyAssetId() {
        navigator.clipboard.writeText(this.props.asset?.id).then(
            () => {
                logNotification(copyAsset.copied);
            },
            () => {
                logError(copyAsset.error);
            }
        );
    }

    private createCompFromVideo() {
        const { story, asset } = this.props;
        const comp = getCompFromAsset(asset);
        this.props.createComposition(story.id, comp).then((c: IComposition) => {
            const tabLabel = `Composition: ${asset.name}`;
            this.props.replaceViewer({
                id: c.id,
                label: tabLabel,
                type: ASSET_TYPES.VIDEO_COMPOSITION
            });
            this.props.doAssetTableHydration(api, story.id);
        });
    }

    private duplicateAsset() {
        const { asset, story } = this.props;
        const newName =
            asset && asset.type === ASSET_TYPES.VIDEO_COMPOSITION
                ? 'New Composition'
                : asset
                ? `Copy of ${asset.name}`
                : 'New Asset';
        api.duplicateAsset(asset.id, newName)
            .then(() => {
                this.props.doAssetTableHydration(api, story.id);
            })
            .catch((e) => {
                console.error(e);
            });
    }

    private decoupleAsset = () => {
        const { asset } = this.props;
        this.props.updateAssetStory(api, asset.id, null);
    };

    private coupleAsset = () => {
        const { asset, story } = this.props;
        this.props.updateAssetStory(api, asset.id, story.id);
    };

    private deleteAsset = () => {
        const { story, asset } = this.props;
        this.props
            .deleteAssets(api, [asset.id], story.id)
            .then((deleted) => {
                if (deleted) {
                    this.props.closeViewer(asset.id);
                    doAssetTableHydration(api, story.id, () => logError(asset.errorPulling));
                }
            })
            .catch(() => {
                logError(asset.errorDeleting);
            });
    };

    private cancelAssetUpload() {
        const { name, assetId } = this.props.asset;
        this.props.cancelAssetUpload(name, api, assetId);
    }

    private replaceAssetHandler() {
        const {
            asset: { id, type }
        } = this.props;

        this.props.setAssetId(id, type);
    }

    private downloadJSONHandler() {
        const {
            asset: { id }
        } = this.props;
        api.getAssetItem(id)
            .then((asset) => {
                const type = typeof asset.data;
                if (type === 'string') {
                    const JSONData = JSON.parse(asset.data);
                    delete JSONData['devConfig'];

                    const downloadLink = document.createElement('a');
                    const data =
                        'data:text/json;charset=utf-8,' +
                        encodeURIComponent(JSON.stringify(JSONData));
                    downloadLink.href = data;
                    downloadLink.download = `${asset.name}.json`;
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    downloadLink.parentNode.removeChild(downloadLink);
                }
            })
            .catch((e) => {
                console.error(e);
            });
    }

    public render() {
        const {
            asset,
            showClose,
            showDelete,
            showDetails,
            showSettings,
            position,
            isViewer,
            editor: { showVariables, fromCrM }
        } = this.props;

        const hideDeleteForCrM =
            asset &&
            asset.type === ASSET_TYPES.VIDEO_COMPOSITION &&
            asset.name === RESERVED_COMP_RENDER_NAME &&
            fromCrM
                ? true
                : false;
        const button = (
            <Button
                size='small'
                style='subtle'>
                {ICON_ELLIPSIS_H}
            </Button>
        );

        const btnDetails = (
            <ButtonMenuItem
                key='asset-ctx-btn-details'
                label={
                    <div className='asset-context-label'>
                        {ICON_LIST}&nbsp;{copy.asset.tooltipAssetDetails}
                    </div>
                }
                onClick={this.props.onDetails}
            />
        );

        const btnSettings = (
            <ButtonMenuItem
                key='asset-ctx-btn-settings'
                label={
                    <div className='asset-context-label'>
                        {ICON_COG}&nbsp;{copy.compositions.settings}
                    </div>
                }
                onClick={this.props.onSettings}
            />
        );

        const btnClose = (
            <ButtonMenuItem
                key={'asset-ctx-btn-close'}
                label={
                    <div className='asset-context-label'>
                        {ICON_TIMES}&nbsp;{copy.compositions.closeTab}
                    </div>
                }
                onClick={this.props.onClose}
            />
        );

        const btnDuplicate = (
            <ButtonMenuItem
                key={'asset-ctx-btn-duplicate'}
                label={
                    <div className='asset-context-label'>
                        {ICON_COPY}&nbsp;{assets.duplicate}
                    </div>
                }
                onClick={this.evtHandlers.onDuplicate}
            />
        );

        const btnDelete = (
            <ButtonMenuItem
                key={'asset-ctx-btn-delete'}
                label={
                    <div className='asset-context-label'>
                        {ICON_TRASH}&nbsp;{assets.delete}
                    </div>
                }
                onClick={this.evtHandlers.onDelete}
            />
        );

        const btnDownload = asset?.id && (
            <ButtonMenuItem
                key={'asset-ctx-btn-download'}
                label={
                    <a
                        className='asset-context-label'
                        href={`${import.meta.env.VITE_IMPOSIUM_BASE}/assets/${asset.id}/download`}
                        target='_blank'
                        download>
                        {ICON_DOWNLOAD}&nbsp;
                        {asset.type === ASSET_TYPES.VIDEO
                            ? copy.asset.downloadOriginalAsset
                            : copy.asset.downloadAsset}
                    </a>
                }
                onClick={() => ''}
            />
        );

        const btnDownloadPreview = asset?.id && (
            <ButtonMenuItem
                key={'asset-ctx-btn-preview-download'}
                label={
                    <a
                        className='asset-context-label'
                        href={`${import.meta.env.VITE_IMPOSIUM_BASE}/assets/${
                            asset.id
                        }/download?url_type=preview`}
                        target='_blank'
                        download>
                        {ICON_DOWNLOAD}&nbsp;{copy.asset.downloadAssetPreview}
                    </a>
                }
                onClick={() => ''}
            />
        );

        const btnDecouple = (
            <ButtonMenuItem
                key={'asset-ctx-btn-unassociate'}
                label={
                    <div className='asset-context-label'>
                        {ICON_GLOBE}&nbsp;{copy.asset.decoupleAsset}
                    </div>
                }
                onClick={this.evtHandlers.onDecouple}
            />
        );

        const btnCouple = (
            <ButtonMenuItem
                key={'asset-ctx-btn-associate'}
                label={
                    <div className='asset-context-label'>
                        {ICON_CROSSHAIRS}&nbsp;{copy.asset.coupleAsset}
                    </div>
                }
                onClick={this.evtHandlers.onCouple}
            />
        );

        const btnReplace = (
            <ButtonMenuItem
                key={'replace-asset-file'}
                label={
                    <div className='asset-context-label'>
                        {ICON_FILE_PEN}&nbsp;{copy.asset.replaceFile}
                    </div>
                }
                onClick={this.evtHandlers.onReplace}
            />
        );

        const btnDownloadHTML = (
            <ButtonMenuItem
                key={'download-html'}
                label={
                    <div className='asset-context-label'>
                        {ICON_DOWNLOAD}&nbsp;{copy.asset.downloadJSON}
                    </div>
                }
                onClick={this.evtHandlers.onDownloadJSON}
            />
        );

        let type = 'Video/Image';

        if (asset?.type) {
            type = asset.type.charAt(0).toUpperCase() + asset.type.slice(1);
        }

        const btnCreateComp = (
            <ButtonMenuItem
                key='asset-ctx-create-comp'
                label={
                    <div className='asset-context-label'>
                        {ICON_LAYER_GROUP}&nbsp;
                        {timeline.createNewFromAsset.replace('[type]', type)}
                    </div>
                }
                onClick={this.evtHandlers.createCompFromVideo}
            />
        );

        const btnCopySource = (
            <ButtonMenuItem
                key={'asset-ctx-btn-copy-source'}
                label={
                    <div className='asset-context-label'>
                        {ICON_LINK}&nbsp;{copy.asset.copyUrl}
                    </div>
                }
                onClick={this.evtHandlers.onCopyUrl}
            />
        );

        const btnCopyId = (
            <ButtonMenuItem
                key={'asset-ctx-btn-copy-id'}
                label={
                    <div className='asset-context-label'>
                        {ICON_COPY}&nbsp;{copy.asset.copyId}
                    </div>
                }
                onClick={this.evtHandlers.onCopyId}
            />
        );

        const btnCancelUpload = (
            <ButtonMenuItem
                key={'asset-ctx-btn-cancel-upload'}
                label={<div className='asset-context-label'>{ICON_BAN}</div>}
                onClick={this.evtHandlers.onCancel}
            />
        );

        let items = [];

        switch (asset?.type) {
            case ASSET_TYPES.IMAGE:
                items = [btnDownload, btnCopySource, btnCopyId];
                if (showDetails) {
                    items.push(btnDetails);
                }

                if (!showVariables) {
                    items.push(btnCreateComp);
                    items.push(btnDuplicate);
                }

                if (!isViewer && !showVariables) {
                    items.push(btnReplace);
                }
                break;
            case ASSET_TYPES.VIDEO:
                items = [btnDownload, btnDownloadPreview, btnCopySource, btnCopyId];
                if (showDetails) {
                    items.push(btnDetails);
                }

                if (!showVariables) {
                    items.push(btnCreateComp);
                    items.push(btnDuplicate);
                }

                if (!isViewer && !showVariables) {
                    items.push(btnReplace);
                }
                break;

            // video compositions
            case ASSET_TYPES.VIDEO_COMPOSITION:
                if (showSettings) {
                    items.push(btnSettings);
                }
                if (!showVariables) {
                    items.push(btnDuplicate);
                }
                break;

            // default
            case ASSET_TYPES.AFTER_EFFECT:
            case ASSET_TYPES.FONT:
            case ASSET_TYPES.IMAGE_SEQUENCE:
            case ASSET_TYPES.TEMPLATE:
            case ASSET_TYPES.LUT:
            case ASSET_TYPES.UPLOAD:
            case ASSET_TYPES.AUDIO:
                items = [btnDownload, btnCopySource, btnCopyId];
                if (!isViewer && !showVariables) {
                    items.push(btnReplace);
                    items.push(btnDuplicate);
                }
                break;
            case ASSET_TYPES.HTML:
                items = [btnDownloadHTML];

                if (!isViewer && !showVariables) {
                    items.push(btnDuplicate);
                    items.push(btnReplace);
                }
                break;
        }

        // If the project is not from CrM, this is the asset table menu, and we're not showing variable assets...
        if (!fromCrM && !isViewer && !showVariables) {
            if (asset?.story_id && asset?.type !== ASSET_TYPES.VIDEO_COMPOSITION) {
                items.push(btnDecouple);
            }
            if (!asset?.story_id && asset?.type !== ASSET_TYPES.VIDEO_COMPOSITION) {
                items.push(btnCouple);
            }
        }

        if (showDelete && !hideDeleteForCrM) {
            items.push(btnDelete);
        }
        if (showClose) {
            items.push(btnClose);
        }

        if (asset?.type === ASSET_TYPES.UPLOAD) {
            const { percent, assetId } = asset;
            if ((percent === 100 && assetId) || percent !== 100) {
                return btnCancelUpload;
            }
        } else {
            return (
                <ButtonMenu
                    position={position}
                    items={items}
                    button={button}
                    isPortal={true}
                />
            );
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            createComposition,
            doAssetTableHydration,
            replaceViewer,
            deleteAssets,
            updateAssetStory,
            closeViewer,
            cancelAssetUpload
        },
        dispatch
    );
};

const mapStateToProps = (state): any => {
    return { story: state.story, editor: state.editor };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetContextMenu);
