import * as React from 'react';
import { FRAME_WIDTH, MIN_LABEL_WIDTH } from '../../constants/timeline';
import { Trackbar } from './Trackbar';
import { getFrameFromXPos } from '../../util/timeline';

interface ILabelsProps {
    activeFrame: number;
    frames: number;
    rate: number;
    scale: number;
    width: number;
    height: number;
    x: number;
    jumpToFrame(f: number): void;
    setDragState?(state: any): void;
}

interface ILabelsState {
    dragging: boolean;
    dragStartOffset: number;
}

class Labels extends React.PureComponent<ILabelsProps, ILabelsState> {
    private evtHandlers = {
        dragStart: (e, i) => this.onDragStart(e, i),
        dragEnd: (e, i) => this.onDragEnd(e, i),
        drag: (e, i) => this.onDrag(e, i)
    };

    constructor(props) {
        super(props);

        this.state = {
            dragging: false,
            dragStartOffset: 0
        };
    }

    private onDragStart(e, dragInfo) {
        this.setFrame(dragInfo.x);
        this.setState({
            dragging: true,
            dragStartOffset: dragInfo.x
        });
        this.setFrame(dragInfo.x);
        if (this.props.setDragState) {
            this.props.setDragState({
                draggingHandle: false,
                draggingPlayhead: true,
                draggingLayer: false
            });
        }
    }

    private onDragEnd(e, dragInfo) {
        this.setState({
            dragging: false,
            dragStartOffset: 0
        });
        if (this.props.setDragState) {
            this.props.setDragState({
                draggingHandle: false,
                draggingPlayhead: false,
                draggingLayer: false
            });
        }
    }

    private onDrag(e, dragInfo) {
        this.setFrame(this.state.dragStartOffset + dragInfo.offsetX);
    }

    private setFrame(x) {
        const { scale, frames, activeFrame } = this.props;
        const frame = Math.max(0, Math.min(getFrameFromXPos(x, scale), frames - 1));
        if (frame !== activeFrame) {
            this.props.jumpToFrame(frame);
        }
    }

    public getTicks() {
        const { frames, rate, scale } = this.props;
        const ticks = [];
        const fps = Math.round(rate);
        let step = fps;
        let space = FRAME_WIDTH * scale * step;

        while (space < MIN_LABEL_WIDTH) {
            step += fps;
            space = FRAME_WIDTH * scale * step;
        }

        for (let i = 0; i <= frames; i += step) {
            const time = Math.floor(i / rate);
            const label = `${time}`;
            const x = FRAME_WIDTH * scale * i;
            const style = {
                left: `${x}px`
            };

            ticks.push(
                <div
                    key={i}
                    className='tick'
                    style={style}>
                    <div className='line' />
                    <div className='label'>{label}</div>
                </div>
            );
        }
        return ticks;
    }

    public render() {
        const { x, width } = this.props;
        const style = {
            left: `${x}px`,
            width: `${width}px`
        };

        const snapGrid = {
            x: FRAME_WIDTH * this.props.scale
        };

        return (
            <div
                className='labels'
                style={style}>
                <Trackbar
                    axis='x'
                    grid={snapGrid}
                    onStop={this.evtHandlers.dragEnd}
                    onStart={this.evtHandlers.dragStart}
                    onDrag={this.evtHandlers.drag}>
                    <div className='bar' />
                    {this.getTicks()}
                </Trackbar>
            </div>
        );
    }
}

export default Labels;
