import * as React from 'react';
import EncodingSettingConfig from './EncodingSettingConfig';
import { SelectField } from '@imposium-hub/components';
import { ENCODING_SETTING_PRESET_OPTIONS, ENCODING_SETTING_PRESETS } from '../constants/story';
import { IVideoEncodingSetting, NEW_ENCODING_SETTING } from '../constants/snippets';
import { fields as copy } from '../constants/copy';

interface IEncodingSettingListProps {
    fromCrM?: boolean;
    config: IVideoEncodingSetting[];
    onChange(s): void;
    isNameDup?(b): void;
}

interface IENcodingSettingListState {
    activePreset: string;
    newPreset: IVideoEncodingSetting;
}

class EncodingSettingList extends React.PureComponent<
    IEncodingSettingListProps,
    IENcodingSettingListState
> {
    constructor(props) {
        super(props);

        this.state = {
            newPreset: null,
            activePreset: ENCODING_SETTING_PRESET_OPTIONS[0].value
        };
    }

    public updateSetting(index, config) {
        const settings = [...this.props.config];

        let isNameDup = false;
        for (let i = 0; i < settings.length; i++) {
            if (i !== index) {
                if (settings[i].name === config.name) {
                    isNameDup = true;
                }
            }
        }

        if (this.props.isNameDup) {
            this.props.isNameDup(isNameDup);
        }

        settings[index] = config;
        this.props.onChange(settings);
    }

    private deleteSetting(index) {
        const settings = [...this.props.config];
        settings.splice(index, 1);
        this.props.onChange(settings);
    }

    private duplicateSetting(index) {
        const settings = [...this.props.config];
        const newSetting = { ...settings[index] };
        newSetting.name = `${newSetting.name} 2`;
        settings.push(newSetting);
        this.props.onChange(settings);
    }

    private updateNewSetting(config) {
        this.setState({ newPreset: config });
    }

    private checkNameHandler(setting, settings, num = 0) {
        let newName = `${setting.name}`;
        if (num > 0) {
            newName = `${setting.name} ${num}`;
        }
        const checkNewName = settings.findIndex((s) => s.name === newName);
        if (checkNewName === -1) {
            setting.name = newName;
            settings.push(setting);
            this.props.onChange(settings);
        } else {
            const numOfRep = num + 1;
            this.checkNameHandler(setting, settings, numOfRep);
        }
    }

    private presetChanged(v) {
        if (v === 'New') {
            this.setState({ newPreset: { ...NEW_ENCODING_SETTING } });
        } else {
            const setting = { ...ENCODING_SETTING_PRESETS[v] };
            const settings = [...this.props.config];
            this.checkNameHandler(setting, settings);
        }
    }

    private createNewSetting() {
        const settings = [...this.props.config];
        settings.push(this.state.newPreset);
        this.props.onChange(settings);
        this.setState({ newPreset: null });
    }

    public render() {
        const { config, fromCrM } = this.props;
        const { newPreset } = this.state;

        return (
            <>
                {!fromCrM && (
                    <SelectField
                        label={copy.encoding.addVideoOutput}
                        tooltip={copy.encoding.tooltipAddVideoOutput}
                        width='100%'
                        disableFirst={true}
                        onChange={(v) => this.presetChanged(v)}
                        value={this.state.activePreset}
                        options={ENCODING_SETTING_PRESET_OPTIONS}
                    />
                )}
                {config.map((setting: IVideoEncodingSetting, i) => {
                    return (
                        <EncodingSettingConfig
                            forceOpen={fromCrM}
                            disableDelete={fromCrM}
                            disableDuplicate={fromCrM}
                            config={setting}
                            settings={config}
                            onUpdate={(c) => this.updateSetting(i, c)}
                            onDelete={() => this.deleteSetting(i)}
                            onDuplicate={() => this.duplicateSetting(i)}
                            key={i}
                        />
                    );
                })}
                {newPreset && (
                    <EncodingSettingConfig
                        config={newPreset}
                        settings={config}
                        isNew={true}
                        onCreate={() => this.createNewSetting()}
                        onCancel={() => this.updateNewSetting(null)}
                        onUpdate={(c) => this.updateNewSetting(c)}
                    />
                )}
            </>
        );
    }
}

export default EncodingSettingList;
