import * as React from 'react';
import {
    ENCODING_SETTING_FORMAT_OPTIONS,
    ENCODING_SETTING_RESIZE_OPTIONS,
    ENCODING_SETTING_PRESET_SPEED_OPTIONS,
    ENCODING_SETTING_AUDIO_BITRATE_OPTIONS,
    ENCODING_SETTING_VIDEO_PROFILE_OPTIONS,
    ENCODING_SETTING_VIDEO_QUALITY_OPTIONS,
    VIDEO_FORMATS,
    ENCODING_SETTING_VIDEO_DEFAULTS,
    ENCODING_SETTING_AUDIO_DEFAULTS,
    ENCODING_SETTING_AUDIO_QUALITY_OPTIONS
} from '../constants/story';
import { TextField, SelectField, NumberField, Card, HRule, Button } from '@imposium-hub/components';
import { fields as copy } from '../constants/copy';
import SuperUserOnly from './SuperUserOnly';
import { IVideoEncodingSetting } from '../constants/snippets';
import { ICON_CHECK, ICON_COPY, ICON_TIMES, ICON_TRASH } from '../constants/icons';
import {
    MAX_OUTPUT_HEIGHT,
    MAX_OUTPUT_WIDTH,
    MIN_OUTPUT_DIMENSION,
    MIN_VIDEO_BITRATE
} from '../constants/editor';

interface IEncodingSettingConfigProps {
    forceOpen?: boolean;
    disableDelete?: boolean;
    disableDuplicate?: boolean;
    isNew?: boolean;
    settings?: IVideoEncodingSetting[];
    config: IVideoEncodingSetting;
    onUpdate(c): any;
    onCancel?(): void;
    onCreate?(): any;
    onDelete?(): any;
    onDuplicate?(): any;
}

interface IEncodingSettingConfigState {
    isNameDup: boolean;
}

class EncodingSettingConfig extends React.PureComponent<
    IEncodingSettingConfigProps,
    IEncodingSettingConfigState
> {
    constructor(props) {
        super(props);

        this.state = {
            isNameDup: false
        };
    }

    public inputChanged(key, value) {
        const newConfig = { ...this.props.config };
        newConfig[key] = value;

        // const { extension, width, height } = newConfig;
        // const { MP4 } = VIDEO_FORMATS;
        // if (
        //     (key === 'width' || key === 'height') &&
        //     value < MIN_OUTPUT_DIMENSION &&
        //     extension === MP4
        // ) {
        //     const currentVideoBitrate = parseInt(this.findConfigValue('video', '-b:v'), 10);
        //     newConfig['video'] = `-c:v libx264 -preset fast -rc:v vbr ${this.getBitrateFlags(
        //         currentVideoBitrate,
        //         width,
        //         height
        //     )}`;
        //     newConfig['audio'] = this.getAudioConfig(extension);
        // }

        // if ((key === 'width' || key === 'height') && value > MIN_OUTPUT_DIMENSION) {
        //     if (extension === MOV) {
        //         newConfig['video'] = ENCODING_SETTING_VIDEO_DEFAULTS[MOV];
        //         newConfig['audio'] = ENCODING_SETTING_AUDIO_DEFAULTS[MOV];
        //     } else {
        //         newConfig['video'] = this.getVidioConfig(extension);
        //         newConfig['audio'] = this.getAudioConfig(extension);
        //     }
        // }

        this.props.onUpdate(newConfig);
    }

    private nameCheckHandler(v) {
        const { settings, isNew } = this.props;
        if (isNew) {
            const isDup = settings.findIndex((s) => s.name === v);
            if (isDup >= 0) {
                this.setState({ isNameDup: true });
            } else {
                this.setState({ isNameDup: false });
            }
        } else {
            const dupSetting = [];
            settings.map((s) => {
                if (s.name === v) {
                    dupSetting.push(s);
                }
            });

            if (dupSetting.length >= 1) {
                this.setState({ isNameDup: true });
            } else {
                this.setState({ isNameDup: false });
            }
        }
    }

    private evenNumberCheck(key, value) {
        if (value !== '') {
            let val = parseFloat(value);
            if (val % 2 !== 0) {
                val = val + 1;
                this.inputChanged(key, val);
            }
        }
    }

    private getVidioConfig(format) {
        const {
            config: { width, height }
        } = this.props;
        const currentVideoBitrate = parseInt(this.findConfigValue('video', '-b:v'), 10);
        return `${ENCODING_SETTING_VIDEO_DEFAULTS[format]} ${this.getBitrateFlags(
            currentVideoBitrate,
            width,
            height
        )}`;
    }

    private getAudioConfig(format) {
        const currentAudioBitrate = parseInt(this.findConfigValue('audio', '-b:a'), 10);
        return `${ENCODING_SETTING_AUDIO_DEFAULTS[format]} ${this.getAudioBitrateFlags(
            currentAudioBitrate
        )}`;
    }

    private updateFormat(configValue) {
        const { config } = this.props;

        let vConfig;
        let aConfig;
        const newConfig = { ...config };

        switch (configValue) {
            case VIDEO_FORMATS.MP4:
                newConfig['video'] = this.getVidioConfig(VIDEO_FORMATS.MP4);
                newConfig['audio'] = this.getAudioConfig(VIDEO_FORMATS.MP4);
                newConfig['extension'] = configValue;
                this.props.onUpdate(newConfig);

                return;

            case VIDEO_FORMATS.FLV:
                newConfig['video'] = this.getVidioConfig(VIDEO_FORMATS.FLV);
                newConfig['audio'] = this.getAudioConfig(VIDEO_FORMATS.FLV);
                newConfig['extension'] = configValue;
                this.props.onUpdate(newConfig);

                return;

            case VIDEO_FORMATS.MKV:
                newConfig['video'] = this.getVidioConfig(VIDEO_FORMATS.MKV);
                newConfig['audio'] = this.getAudioConfig(VIDEO_FORMATS.MKV);
                newConfig['extension'] = configValue;
                this.props.onUpdate(newConfig);

                return;

            case VIDEO_FORMATS.MOV:
                vConfig = ENCODING_SETTING_VIDEO_DEFAULTS[VIDEO_FORMATS.MOV];
                aConfig = ENCODING_SETTING_AUDIO_DEFAULTS[VIDEO_FORMATS.MOV];
                newConfig['video'] = vConfig;
                newConfig['audio'] = aConfig;
                newConfig['extension'] = configValue;
                this.props.onUpdate(newConfig);

                return;
            default:
                break;
        }
    }

    // Search string for flag and return value
    private findConfigValue(type, configValue?): any {
        const {
            config: { video, audio }
        } = this.props;

        if (configValue) {
            const configArray = type === 'video' ? video.split(' ') : audio.split(' ');
            const findFlag = configArray.indexOf(configValue);

            if (findFlag === -1) {
                return false;
            }
            return configArray[findFlag + 1];
        }

        return false;
    }

    private replaceConfig(flag, newValue, configStr): string {
        const findFlag = configStr.indexOf(flag);

        if (findFlag !== -1) {
            const startIndex = findFlag + flag.length + 1;
            const trimmedString = configStr.substring(startIndex, configStr.length);
            const currentValue = trimmedString.trim().split(' ')[0];
            const endIndex =
                currentValue[0] === '-' ? startIndex - 1 : startIndex + currentValue.length;

            return configStr.substring(0, startIndex) + newValue + configStr.substring(endIndex);
        }
    }

    private confirmDelete() {
        const { config } = this.props;

        const conf = confirm(copy.global.deletePrompt.replace('[name]', config.name));
        if (conf) {
            this.props.onDelete();
        }
    }

    private updateBitrate(value): void {
        const newValue = value || 0;
        const {
            config: { video }
        } = this.props;

        const bitrate = this.replaceConfig('-b:v', `${Math.floor(newValue)}k`, video);
        const maxrate = this.replaceConfig('-maxrate:v', `${Math.floor(newValue * 1.5)}k`, bitrate);
        const bufsize = this.replaceConfig('-bufsize:v', `${Math.floor(newValue * 2)}k`, maxrate);

        this.inputChanged('video', bufsize);
    }

    private getBitrateFlags(bitrate, width = 0, height = 0): string {
        if (!bitrate) {
            bitrate = Math.round((width * height) / 518 / 100) * 100; // 518 is the magic number... don't ask questions.
        }
        return `-b:v ${Math.floor(bitrate)}k -maxrate:v ${Math.floor(
            bitrate * 1.5
        )}k -bufsize:v ${Math.floor(bitrate * 2)}k`;
    }

    private getAudioBitrateFlags(bitrate) {
        if (!bitrate) {
            bitrate = 192;
        }
        return `-b:a ${bitrate}k`;
    }

    private getAudioQualityFlags(quality) {
        if (!quality) {
            quality = 5;
        }
        return `-qscale:a ${quality}`;
    }

    private updateAudioBitrate(value) {
        const {
            config: { audio }
        } = this.props;
        const newValue = value || 0;
        const newConfig = this.replaceConfig('-b:a', `${Math.floor(newValue)}k`, audio);

        this.inputChanged('audio', newConfig);
    }

    private updateAudioQuality(value) {
        const {
            config: { audio }
        } = this.props;
        const newConfig = this.replaceConfig('-qscale:a', value, audio);

        this.inputChanged('audio', newConfig);
    }

    private updatePreset(value, flag) {
        const {
            config: { video }
        } = this.props;

        let newConfig;

        if (flag === '-cq:v') {
            if (value === 'custom') {
                newConfig = `${
                    ENCODING_SETTING_VIDEO_DEFAULTS[VIDEO_FORMATS.MP4]
                } ${this.getBitrateFlags(4000)}`;
            } else {
                newConfig = `${
                    ENCODING_SETTING_VIDEO_DEFAULTS[VIDEO_FORMATS.MP4]
                } -cq:v ${value} -qmin ${value} -qmax ${value} `;
            }
        } else {
            newConfig = this.replaceConfig(flag, value, video);
        }

        this.inputChanged('video', newConfig);
    }

    public render() {
        const {
            config,
            isNew,
            config: { extension, width, height },
            disableDelete,
            disableDuplicate,
            forceOpen
        } = this.props;

        const { isNameDup } = this.state;

        const disableSave = !width || !height || isNameDup;

        const minOutPut = MIN_OUTPUT_DIMENSION;

        const buttons = isNew ? (
            <>
                <Button
                    size='small'
                    style='subtle'
                    color='danger'
                    tooltip={copy.global.cancel}
                    onClick={this.props.onCancel}>
                    {ICON_TIMES}
                </Button>
                <Button
                    size='small'
                    style='subtle'
                    color='primary'
                    disabled={disableSave}
                    tooltip={copy.encoding.tooltipCreateNewPreset}
                    onClick={() => this.props.onCreate()}>
                    {ICON_CHECK}
                </Button>
            </>
        ) : (
            <>
                {!disableDuplicate && (
                    <Button
                        size='small'
                        color='secondary'
                        onClick={() => this.props.onDuplicate()}
                        tooltip={copy.global.duplicate}>
                        {ICON_COPY}
                    </Button>
                )}
                {!disableDelete && (
                    <Button
                        size='small'
                        onClick={() => this.confirmDelete()}
                        tooltip={copy.global.delete}>
                        {ICON_TRASH}
                    </Button>
                )}
            </>
        );

        const widthAndHeight = (
            <>
                <NumberField
                    label={copy.global.width}
                    tooltip={copy.encoding.tooltipWidth}
                    value={config['width']}
                    width='50%'
                    onChange={(v) => this.inputChanged('width', v)}
                    max={MAX_OUTPUT_WIDTH}
                    min={minOutPut}
                    onBlur={(v) => this.evenNumberCheck('width', v)}
                />

                <NumberField
                    label={copy.global.height}
                    tooltip={copy.encoding.tooltipHeight}
                    value={config['height']}
                    width='50%'
                    onChange={(v) => this.inputChanged('height', v)}
                    max={MAX_OUTPUT_HEIGHT}
                    min={minOutPut}
                    onBlur={(v) => this.evenNumberCheck('height', v)}
                />
            </>
        );

        return (
            <Card
                style='small'
                title={config.name}
                collapsable={!isNew}
                open={isNew || forceOpen}>
                <div className='encoding-setting-config'>
                    <TextField
                        className={isNameDup ? 'warning' : ''}
                        label={copy.global.name}
                        tooltip={copy.encoding.tooltipName}
                        info={isNameDup ? copy.encoding.nameWarning : ''}
                        value={config['name']}
                        onChange={(v) => {
                            this.inputChanged('name', v);
                            this.nameCheckHandler(v.trim());
                        }}
                    />

                    {widthAndHeight}

                    <SelectField
                        label={copy.encoding.format}
                        tooltip={copy.encoding.tooltipFormat}
                        options={ENCODING_SETTING_FORMAT_OPTIONS}
                        value={extension}
                        width='50%'
                        onChange={(newValue) => this.updateFormat(newValue)}
                    />

                    <SelectField
                        label={copy.encoding.resize}
                        tooltip={copy.encoding.tooltipResize}
                        options={ENCODING_SETTING_RESIZE_OPTIONS}
                        value={config['resize']}
                        width='50%'
                        onChange={(v) => this.inputChanged('resize', v)}
                    />

                    {this.findConfigValue('video', '-preset') ? (
                        <SelectField
                            label={copy.encoding.videoPreset}
                            tooltip={copy.encoding.tooltipVideoPreset}
                            options={ENCODING_SETTING_PRESET_SPEED_OPTIONS}
                            value={this.findConfigValue('video', '-preset')}
                            width='50%'
                            onChange={(v) => this.updatePreset(v, '-preset')}
                        />
                    ) : (
                        ''
                    )}

                    {this.findConfigValue('video', '-profile') ? (
                        <SelectField
                            label={copy.encoding.videoProfile}
                            tooltip={copy.encoding.tooltipVideoProfile}
                            options={ENCODING_SETTING_VIDEO_PROFILE_OPTIONS}
                            value={this.findConfigValue('video', '-profile')}
                            width='50%'
                            onChange={(newValue) => this.updatePreset(newValue, '-profile')}
                        />
                    ) : (
                        ''
                    )}

                    {extension !== VIDEO_FORMATS.MOV ? (
                        <SelectField
                            label={copy.encoding.videoQuality}
                            tooltip={copy.encoding.tooltipVideoQuality}
                            options={ENCODING_SETTING_VIDEO_QUALITY_OPTIONS}
                            value={this.findConfigValue('video', '-cq:v') || 'custom'}
                            width='50%'
                            onChange={(newValue) => this.updatePreset(newValue, '-cq:v')}
                        />
                    ) : (
                        ''
                    )}

                    {this.findConfigValue('video', '-b:v') ? (
                        <>
                            <NumberField
                                min={MIN_VIDEO_BITRATE}
                                label={copy.encoding.videoBitrate}
                                tooltip={copy.encoding.tooltipVideoBitrate}
                                width='50%'
                                value={parseInt(this.findConfigValue('video', '-b:v'), 10) || null}
                                onChange={(value) => this.updateBitrate(value)}
                            />
                        </>
                    ) : (
                        ''
                    )}

                    {this.findConfigValue('audio', '-b:a') ? (
                        <SelectField
                            label={copy.encoding.audioBitrate}
                            tooltip={copy.encoding.tooltipAudioBitrate}
                            options={ENCODING_SETTING_AUDIO_BITRATE_OPTIONS}
                            value={parseInt(this.findConfigValue('audio', '-b:a'), 10) || ''}
                            width='50%'
                            onChange={(v) => this.updateAudioBitrate(v)}
                        />
                    ) : (
                        ''
                    )}

                    {this.findConfigValue('audio', '-qscale:a') ? (
                        <SelectField
                            label={copy.encoding.audioQuality}
                            tooltip={copy.encoding.tooltipAudioQuality}
                            options={ENCODING_SETTING_AUDIO_QUALITY_OPTIONS}
                            value={parseInt(this.findConfigValue('audio', '-qscale:a'), 10) || ''}
                            width='50%'
                            onChange={(v) => this.updateAudioQuality(v)}
                        />
                    ) : (
                        ''
                    )}

                    <SuperUserOnly>
                        <TextField
                            label={copy.encoding.video}
                            tooltip={copy.encoding.tooltipVideo}
                            value={config['video']}
                            controlled={true}
                            onSubmit={(v) => this.inputChanged('video', v)}
                        />
                        <TextField
                            label={copy.encoding.audio}
                            tooltip={copy.encoding.tooltipAudio}
                            value={config['audio']}
                            controlled={true}
                            onSubmit={(v) => this.inputChanged('audio', v)}
                        />
                    </SuperUserOnly>

                    <HRule style='subtle' />

                    <div style={{ textAlign: 'right' }}>{buttons}</div>
                </div>
            </Card>
        );
    }
}

export default EncodingSettingConfig;
