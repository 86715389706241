import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createComposition } from '../../redux/actions/compositions';
import { doAssetTableHydration, IImposiumAPI } from '@imposium-hub/components';
import { getCompFromAsset } from '../../util/story';
import { ASSET_TYPES } from '../../constants/story';
import { ILayeredComposition, IStory } from '../../constants/snippets';
import { addViewer, replaceViewer } from '../../redux/actions/story';
import { AssetDropTarget } from './AssetDropTarget';
import { ICON_LAYER_GROUP, ICON_PLUS } from '../../constants/icons';
import { timeline as copy } from '../../constants/copy';
import { VIEWER_TYPES } from '../../constants/viewer';
import { api } from '../../constants/app';
import { logError } from '../../util/notifications';
import { capitalize } from '../../util/general';

interface IEmptyLayeredTimelineProps {
    story: IStory;
    addViewer(c: any);
    replaceViewer(c: any);
    createComposition(sId: string, comp: ILayeredComposition, name?: string): any;
    doAssetTableHydration(apiInstance: IImposiumAPI, sId): void;
}

interface IEmptyLayeredTimelineState {
    assetOver: boolean;
    assetType: string;
}

class EmptyLayeredTimeline extends React.PureComponent<
    IEmptyLayeredTimelineProps,
    IEmptyLayeredTimelineState
> {
    constructor(p) {
        super(p);

        this.state = {
            assetOver: false,
            assetType: ''
        };
    }

    private checkHover(monitor) {
        const type = monitor.canDrop();
        const isOver = monitor.isOver();
        if (type !== false && isOver !== this.state.assetOver) {
            const assetType = capitalize(type);
            this.setState({ assetOver: isOver, assetType });
        }
    }

    private createNewComposition(asset?) {
        const { story } = this.props;
        if (asset) {
            const comp = getCompFromAsset(asset);
            if (comp) {
                this.props
                    .createComposition(story.id, comp, asset.name)
                    .then((c: ILayeredComposition) => {
                        this.props.replaceViewer({
                            id: c.id,
                            label: `Composition: ${asset.name}`,
                            type: ASSET_TYPES.VIDEO_COMPOSITION
                        });
                        this.props.doAssetTableHydration(api, story.id);
                    })
                    .catch((e) => {
                        logError(copy.errorCreatingComp);
                    });
            } else {
                logError(copy.wrongCompFileTypeDrop);
            }
        } else {
            this.props.addViewer({
                id: VIEWER_TYPES.NEW_COMPOSITION,
                label: copy.newComposition,
                type: VIEWER_TYPES.NEW_COMPOSITION
            });
        }
    }

    public render() {
        const { assetOver, assetType } = this.state;
        const modifier = assetOver ? 'asset-over' : '';

        const btnCopy = assetOver
            ? copy.createNewFromAsset.replace('[type]', assetType)
            : copy.createNewPrompt;
        const icon = assetOver ? ICON_PLUS : ICON_LAYER_GROUP;

        return (
            <div className={`empty-layered-timeline ${modifier}`}>
                <AssetDropTarget
                    onExternalCollect={(o) => this.checkHover(o)}
                    onAssetDrop={(a) => this.createNewComposition(a)}>
                    <h1>{copy.createNewHeader}</h1>
                    <div
                        className='composition-prompt'
                        onClick={() => this.createNewComposition()}>
                        <div className='icon'>
                            {icon}
                            <br />
                        </div>
                        <h2>{btnCopy}</h2>
                    </div>
                </AssetDropTarget>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch): any => {
    return bindActionCreators(
        {
            createComposition,
            doAssetTableHydration,
            addViewer,
            replaceViewer
        },
        dispatch
    );
};

const mapStateToProps = (state): any => {
    return {
        story: state.story
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmptyLayeredTimeline);
