import * as React from 'react';
import {
    ICompositionLayer,
    NEW_MT_QUAD_POSITION,
    NEW_MT_RECT_POSITION,
    NEW_QUAD_POSITION,
    NEW_RECT_POSITION
} from '../../constants/snippets';
import {
    ICON_EYE,
    ICON_LOCK,
    ICON_VOLUME_UP,
    ICON_CARET_DOWN,
    ICON_CARET_RIGHT,
    layerTypeIcon,
    MOTION_BLUR
} from '../../constants/icons';
import { isVisibleLayer, isAudioLayer } from '../../util/story';
import { ASSET_TYPES, SelectField, TextField } from '@imposium-hub/components';
import {
    LAYER_DND_TYPES,
    LAYER_EXPANDED_ROW_HEIGHT,
    LAYER_ROW_HEIGHT
} from '../../constants/timeline';
import { useDrag } from 'react-dnd';
import { LayerKeyframeControls } from './LayerKeyframeControls';
import {
    COMPOSITION_LAYER_TYPES,
    EFFECT_BLEND_MODE_OPTIONS,
    LAYER_POSITION_TYPE_OPTIONS,
    LAYER_RESIZE_OPTIONS,
    LAYOUT_UNITS,
    OVERLAY_POSITION_TYPES
} from '../../constants/story';
import store from '../../redux/store';
import { updateLayerHandler } from '../../util/timeline';
import { LOWER_PANELS } from '../../constants/editor';
import { setComposition } from '../../redux/actions/compositions';
import { updateEditorConfig } from '../../redux/actions/editor';
import { addViewer } from '../../redux/actions/story';
import { getCopyPropIdButton } from '../TextLayerOptions';
import { getSourceKey } from '../viewer/composition-preview/LayeredCompositionPreview';

interface ILayerInfoItemProps {
    active: boolean;
    index: number;
    expanded: boolean;
    activeFrame: number;
    activeKeyframe: string;
    activeTimelineLayer: string;
    updateTimelineState: any;
    showCopyPropIds: boolean;
    config: ICompositionLayer;
    layers: ICompositionLayer[];
    onChange(c: ICompositionLayer): void;
    onMove(index: number, offset: number): void;
    onSelect(layerId: string);
    onSelectKeyframe(keyframeId: string, frameNumber: number);
    onSetLayerMatte(layerId: string, matteLayerId: string);
    onMultiSelect(layerId: string);
    onExpand(layerId: string): void;
    onNotification(e): void;
    onError(e): void;
    layerSources: any;
}

export const LayerInfoItem: React.FC<ILayerInfoItemProps> = (props: ILayerInfoItemProps) => {
    const [, drag] = useDrag({
        type: LAYER_DND_TYPES.LAYER_NAME,
        item: {
            name: props.config.name,
            layerData: props.config,
            index: props.index,
            type: LAYER_DND_TYPES.LAYER_NAME
        }
    });

    const {
        expanded,
        config,
        activeFrame,
        activeKeyframe,
        onSelectKeyframe,
        showCopyPropIds,
        onNotification,
        onError
    } = props;

    const isMatte = props.layers.map((l) => l.matte_layer_id).includes(config.id);

    const {
        compositions: { present },
        project: { compositionId }
    } = store.getState();

    const composition = present[compositionId];

    const evtHandlers: any = {
        toggleExpandLayer: () => props.onExpand(props.config.id),
        videoEnabled: (v) => updateConfig('video_enabled', v),
        audioEnabled: (v) => updateConfig('audio_enabled', v),
        locked: (v) => toggleLocked(v),
        motion: (v) => updateMotionBlur(v),
        name: (v) => updateConfig('name', v),
        matte: (v) => updateMatte(v),
        blend: (v) => updateblendMode('blend_mode', v)
    };

    const toggleLocked = (value) => {
        const newConfig = { ...props.config };
        newConfig['locked'] = value;
        props.onChange(newConfig);
        if (props.activeTimelineLayer === newConfig.id && value === true) {
            props.updateTimelineState({ activeTimelineLayer: null });
        }
    };

    const updateConfig = (key, value) => {
        const newConfig = { ...props.config };
        newConfig[key] = value;
        props.onChange(newConfig);
    };

    const updateMatte = (matteLayerId) => {
        const val = matteLayerId === 'None' ? null : matteLayerId;
        props.onSetLayerMatte(props.config.id, val);
    };

    const updateMotionBlur = (value) => {
        const updateEffects = { ...props.config.effects };
        updateEffects['motion_blur'] = value ? 0.2 : 0;
        const newConfig = { ...props.config, effects: { ...updateEffects } };
        props.onChange(newConfig);
    };

    const overwriteConfig = (newConfig) => {
        props.onChange(newConfig);
    };

    const updateblendMode = (key, value) => {
        const newConfig = { ...props.config, ...{ effects: { [key]: value } } };
        props.onChange(newConfig);
    };

    const layerClick = (e) => {
        if (e.shiftKey) {
            props.onMultiSelect(props.config.id);
        } else {
            props.onSelect(props.config.id);
        }
    };

    const layerDoubleClick = () => {
        const {
            config: {
                id: configId,
                name: configName,
                options: {
                    source: { asset_id: id }
                }
            }
        } = props;

        if (id) {
            const tabLabel =
                type === ASSET_TYPES.VIDEO_COMPOSITION
                    ? `Composition: ${configName || id}`
                    : `Asset: ${configName || id}`;

            const layerSourceKey = getSourceKey(compositionId, configId);
            const asset = layerSources[layerSourceKey];

            if (asset) {
                if (type === ASSET_TYPES.VIDEO_COMPOSITION) {
                    const compData = present[id] ? present[id] : asset.data;
                    store.dispatch(
                        updateEditorConfig({
                            activeLowerPanel: LOWER_PANELS.TIMELINE
                        })
                    );
                    store.dispatch(setComposition(id, compData));
                    store.dispatch(addViewer({ id, label: tabLabel, asset, type }));
                }
                store.dispatch(addViewer({ id, label: tabLabel, asset, type }));
            }
        }
    };

    const {
        layerSources,
        config: {
            video_enabled,
            audio_enabled,
            matte_layer_id,
            locked,
            name,
            type,
            id: layerId,
            position,
            position_inputs,
            effects,
            position_inputs: { aspect_mode }
        }
    } = props;

    const vidToggle = isVisibleLayer(type) ? (
        showCopyPropIds ? (
            getCopyPropIdButton(
                true,
                compositionId,
                layerId,
                'video_enabled',
                onNotification,
                onError
            )
        ) : (
            <IconToggle
                selected={video_enabled}
                onChange={evtHandlers.videoEnabled}
                icon={ICON_EYE}
            />
        )
    ) : null;

    const isAudio = isAudioLayer(type);

    const audToggle = isAudio ? (
        showCopyPropIds ? (
            getCopyPropIdButton(
                true,
                compositionId,
                layerId,
                'video_enabled',
                onNotification,
                onError
            )
        ) : (
            <IconToggle
                selected={audio_enabled}
                onChange={evtHandlers.audioEnabled}
                icon={ICON_VOLUME_UP}
            />
        )
    ) : null;
    const activeClass = props.active ? 'active' : '';
    const height = expanded ? LAYER_EXPANDED_ROW_HEIGHT : LAYER_ROW_HEIGHT;
    const itemStyle = {
        height: `${height}px`
    };

    const blendMode = config?.effects ? config?.effects['blend_mode'] : null;

    const getFullscreenRect = () => {
        let baseVideo: any = composition?.videoFile;

        const layerSourceKey = getSourceKey(compositionId, layerId);

        if (layerSources[layerSourceKey]) {
            baseVideo = layerSources[layerSourceKey];
        }

        const compWidth = baseVideo ? baseVideo.width : composition ? composition.width : null;
        const compHeight = baseVideo ? baseVideo.height : composition ? composition.height : null;

        const conf = { ...NEW_RECT_POSITION };

        conf.x = compWidth / 2;
        conf.y = compHeight / 2;
        conf.width = compWidth;
        conf.height = compHeight;
        conf.anchorX = compWidth / 2;
        conf.anchorY = compHeight / 2;

        return conf;
    };

    const onTypeChange = (posType) => {
        let inputs;

        let keyframes = props.config.keyframes;
        if (posType !== OVERLAY_POSITION_TYPES.RECT) {
            keyframes = { ...keyframes, ...{ position: [], anchor: [], scale: [] } };
        }

        switch (posType) {
            case OVERLAY_POSITION_TYPES.MT_QUAD:
                inputs = { ...NEW_MT_QUAD_POSITION };
                break;

            case OVERLAY_POSITION_TYPES.QUAD:
                inputs = { ...NEW_QUAD_POSITION };
                break;

            case OVERLAY_POSITION_TYPES.RECT:
                inputs = getFullscreenRect();
                break;

            case OVERLAY_POSITION_TYPES.MT_RECT:
                inputs = { ...NEW_MT_RECT_POSITION };
                break;
        }

        updateLayerHandler(config, { position: posType, position_inputs: inputs, keyframes });
    };

    const getLayerMatteOptions = () => {
        const { layers } = props;
        const opts = [
            {
                value: null,
                label: 'None'
            }
        ];

        // Figure out which layers are already being used as mattes
        const matteIds = layers.map((layer) => {
            return layer.matte_layer_id ? layer.matte_layer_id : null;
        });

        for (const layer of layers) {
            if (layer.type !== COMPOSITION_LAYER_TYPES.AUDIO && layer.id !== config.id) {
                const option = {
                    label: layer.name,
                    value: layer.id
                };
                if (config.matte_layer_id === layer.id) {
                    opts.push(option);
                } else if (matteIds.indexOf(layer.id) === -1) {
                    opts.push(option);
                }
            }
        }
        return opts;
    };

    const update = (key, value) => {
        const inputs = { ...position_inputs };
        const unitKey = `${key}Unit`;
        const unit =
            key === 'scaleX' || key === 'scaleY'
                ? LAYOUT_UNITS.PERCENT
                : inputs[unitKey] || LAYOUT_UNITS.PIXELS;
        const newVal = unit === LAYOUT_UNITS.PERCENT ? value / 100 : value;
        inputs[key] = newVal;

        updateLayerHandler(config, { position_inputs: inputs });
    };

    const positionTypeSelect =
        type !== COMPOSITION_LAYER_TYPES.TEXT && type !== COMPOSITION_LAYER_TYPES.AUDIO ? (
            <SelectField
                value={position}
                options={LAYER_POSITION_TYPE_OPTIONS}
                onChange={(t) => onTypeChange(t)}
            />
        ) : null;

    const fillTypeSelect =
        type === COMPOSITION_LAYER_TYPES.TEXT ||
        (position === OVERLAY_POSITION_TYPES.RECT && type !== COMPOSITION_LAYER_TYPES.AUDIO) ? (
            <SelectField
                value={aspect_mode}
                options={LAYER_RESIZE_OPTIONS}
                onChange={(v) => update('aspect_mode', v)}
            />
        ) : null;

    const motionBlurBox =
        type === COMPOSITION_LAYER_TYPES.TEXT || type !== COMPOSITION_LAYER_TYPES.AUDIO ? (
            <IconToggle
                selected={effects?.motion_blur && effects.motion_blur === 0.2 ? true : false}
                onChange={evtHandlers.motion}
                icon={MOTION_BLUR}
            />
        ) : null;
    const modeSelect =
        type !== COMPOSITION_LAYER_TYPES.AUDIO ? (
            <SelectField
                options={EFFECT_BLEND_MODE_OPTIONS}
                value={blendMode}
                onChange={evtHandlers.blend}
            />
        ) : null;

    const matteSelect =
        type !== COMPOSITION_LAYER_TYPES.AUDIO ? (
            <SelectField
                options={getLayerMatteOptions()}
                value={matte_layer_id || null}
                onChange={evtHandlers.matte}
            />
        ) : null;

    const matteClass = isMatte ? 'matte' : '';

    return (
        <div
            className={`layer-info-item ${matteClass}`}
            style={itemStyle}>
            <div className='col icon vid'>{vidToggle}</div>
            <div className='col icon aud'>{audToggle}</div>
            <div className='col icon'>
                <IconToggle
                    selected={locked}
                    onChange={evtHandlers.locked}
                    icon={ICON_LOCK}
                />
            </div>
            <div className={`col icon`}>
                <div className={`icon-toggle type ${type.toLowerCase()}`}>
                    {layerTypeIcon(type)}
                </div>
            </div>
            <div className={'col icon'}>
                <ExpandToggle
                    expanded={expanded}
                    onClick={evtHandlers.toggleExpandLayer}
                />
            </div>
            <div
                className={`col name ${activeClass}`}
                ref={drag}
                onClick={layerClick}
                onDoubleClick={layerDoubleClick}>
                <TextField
                    width='100%'
                    value={name}
                    controlled={true}
                    onSubmit={evtHandlers.name}
                />
            </div>
            <div className={`col mode`}>{modeSelect}</div>
            <div className={`col type`}>{positionTypeSelect}</div>
            <div className={`col fill`}>{fillTypeSelect}</div>
            <div className={`col matte`}>{matteSelect}</div>
            <div className={`col icon`}>{motionBlurBox}</div>
            {expanded && (
                <LayerKeyframeControls
                    compositionId={compositionId}
                    onNotification={onNotification}
                    onError={onError}
                    showCopyPropIds={showCopyPropIds}
                    compWidth={getFullscreenRect().width}
                    compHeight={getFullscreenRect().height}
                    onSelectKeyframe={onSelectKeyframe}
                    activeFrame={activeFrame}
                    activeKeyframe={activeKeyframe}
                    config={config}
                    onChange={updateConfig}
                    onOverwrite={overwriteConfig}
                    composition={composition}
                />
            )}
        </div>
    );
};

interface IIconToggleProps {
    selected: boolean;
    onChange(selected: boolean): any;
    icon: any;
}

const IconToggle = ({ icon, selected, onChange }: IIconToggleProps) => {
    const content = selected ? icon : null;

    return (
        <div
            className='icon-toggle'
            onClick={() => onChange(!selected)}>
            {content}
        </div>
    );
};

interface IExpandToggleProps {
    expanded: boolean;
    onClick(exp: boolean): void;
}

const ExpandToggle = ({ expanded, onClick }: IExpandToggleProps) => {
    const icon = expanded ? ICON_CARET_DOWN : ICON_CARET_RIGHT;
    const onIconClick = () => {
        onClick(!expanded);
    };

    return (
        <div
            onClick={onIconClick}
            className='icon-toggle expand'>
            {icon}
        </div>
    );
};
