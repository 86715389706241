import { SOURCE_TYPES } from '../constants/story';
import { api } from '../constants/app';
import { formatTextContent, getTagFilter } from './story';
import { ASSET_TYPES } from '@imposium-hub/components';
import { getLayerPosition } from './timeline';

export const resolveMatteUrl = (maskAssetId, assets) => {
    return new Promise<any>((resolve, reject) => {
        // no source set
        if (!maskAssetId) {
            resolve(null);
            return;
        }

        api.getAssetItem(maskAssetId)
            .then((asset) => {
                if (asset) {
                    resolve(asset);
                } else {
                    resolve(null);
                }
            })
            .catch(() => {
                reject(null);
            });
    });
};

export const resolveMediaUrl = (storyId, source, type, inventory, assets?, compositions?) => {
    return new Promise<any>((resolve, reject) => {
        // no source set
        if (!source) {
            resolve(null);
        }

        // Pull the asset down from the API, and return the src
        if (source.from === SOURCE_TYPES.ASSET) {
            if (!source.asset_id) {
                resolve(null);
            } else {
                // If the change was triggered by a user changing something in the assets, use the assets from state
                if (assets) {
                    const asset = assets.find((a) => {
                        return a.id === source.asset_id;
                    });
                    resolve(asset);
                    // If not, hit the API to pull the asset
                } else {
                    api.getAssetItem(source.asset_id)
                        .then((asset) => {
                            if (!asset) {
                                resolve(null);
                            } else if (
                                asset.type === ASSET_TYPES.VIDEO_COMPOSITION &&
                                compositions &&
                                compositions[asset.id]
                            ) {
                                resolve({
                                    type: ASSET_TYPES.VIDEO_COMPOSITION,
                                    id: asset.id,
                                    data: JSON.stringify(compositions[asset.id])
                                });
                            } else {
                                resolve(asset);
                            }
                        })
                        .catch(() => {
                            reject(null);
                        });
                }
            }

            // Query the asset API via tags, and return the first result
        } else if (source.from === SOURCE_TYPES.ASSET_TAGS) {
            const tags = getTagFilter(source.asset_tags, inventory);

            if (tags.length === 0) {
                resolve(null);
            }

            // If the change was triggered by a user changing something in the assets, use the assets from state
            if (assets) {
                const asset = assets.find((a) => {
                    if (a.type !== type) {
                        return false;
                    }
                    const queryTags = tags.split(',');
                    const assetTags = a.tags;
                    for (const queryTag of queryTags) {
                        if (assetTags?.indexOf(queryTag) === -1) {
                            return false;
                        }
                    }
                    return true;
                });
                if (asset) {
                    resolve(asset);
                } else {
                    resolve(null);
                }
                // If not, hit the API to pull the asset
            } else {
                api.getAssets({ tags }, storyId)
                    .then((res) => {
                        const asset = res.assets[0]; // TODO: handle multiple assets returned with expID pseudo random
                        if (asset) {
                            // Apply the type filter to the assets after it returns
                            if (typeof type === 'string') {
                                if (asset.type === type) {
                                    resolve(asset);
                                }
                            } else {
                                if (type.indexOf(asset.type) !== -1) {
                                    if (
                                        asset.type === ASSET_TYPES.VIDEO_COMPOSITION &&
                                        compositions &&
                                        compositions[asset.id]
                                    ) {
                                        resolve({
                                            type: ASSET_TYPES.VIDEO_COMPOSITION,
                                            id: asset.id,
                                            data: JSON.stringify(compositions[asset.id])
                                        });
                                    } else {
                                        resolve(asset);
                                    }
                                }
                            }
                            resolve(null);
                        } else {
                            resolve(null);
                        }
                    })
                    .catch(() => {
                        reject(null);
                    });
            }

            // find the url in the variables if the preview is set
        } else if (source.from === SOURCE_TYPES.INVENTORY) {
            if (!source.inventory_id) {
                resolve(null);
            } else {
                if (inventory[source.inventory_id]) {
                    resolve({ url: inventory[source.inventory_id] });
                } else {
                    resolve(null);
                }
            }
        } else if (source.from === SOURCE_TYPES.TEXT) {
            const {
                text,
                text_to_speech: { voice, service, model }
            } = source;
            const src = {};
            const copy = formatTextContent(text, inventory);
            if (text.length === 0) {
                resolve(null);
            } else {
                const reader = new FileReader();

                reader.onload = (e) => {
                    const srcUrl = e.target.result;
                    src['type'] = 'audio';
                    src['url'] = srcUrl;
                    resolve(src);
                };
                if (copy.trim() !== '') {
                    api.textToSpeech({
                        text: copy,
                        voice,
                        model,
                        service
                    }).then((res) => reader.readAsDataURL(res));
                }
            }
        } else {
            resolve(null);
        }
    });
};

// Compares the global interimPosition width the source layer position
// Computes deltas, and then applies those to a different layers position
// Used for multi-select transforms
export const getComputedInterimPosition = (
    layers: any[],
    interimPosition: any,
    interimLayerId: string,
    computedLayerId: string,
    compWidth: number,
    compHeight: number,
    activeFrame: number
) => {
    const targetLayer = layers.find((l) => {
        return l.id === interimLayerId;
    });
    const computedLayer = layers.find((l) => {
        return l.id === computedLayerId;
    });

    if (!targetLayer || !computedLayer) {
        return null;
    }

    const targetPos = getLayerPosition(
        targetLayer.position_inputs,
        targetLayer.keyframes,
        activeFrame - targetLayer.start_frame,
        compWidth,
        compHeight,
        false,
        false
    );

    const computedInterim = getLayerPosition(
        computedLayer.position_inputs,
        computedLayer.keyframes,
        activeFrame - computedLayer.start_frame,
        compWidth,
        compHeight,
        false,
        false
    );

    // Compute deltas to apply to the interim position
    const xDelta = interimPosition.x + interimPosition.anchorX - targetPos.x;
    const yDelta = interimPosition.y + interimPosition.anchorY - targetPos.y;
    const widthDelta = interimPosition.width / interimPosition.scaleX - targetPos.width;
    const heightDelta = interimPosition.height / interimPosition.scaleY - targetPos.height;
    const scaleXDelta =
        interimPosition.scaleX !== 1 ? interimPosition.scaleX / targetPos.scaleX : 1;
    const scaleYDelta =
        interimPosition.scaleY !== 1 ? interimPosition.scaleY / targetPos.scaleY : 1;

    // Apply the scale deltas
    computedInterim.scaleX = computedInterim.scaleX * scaleXDelta;
    computedInterim.scaleY = computedInterim.scaleY * scaleYDelta;

    // Use the scale & w/h deltas to compute the interim width & height
    computedInterim.width = (computedInterim.width + widthDelta) * computedInterim.scaleX;
    computedInterim.height = (computedInterim.height + heightDelta) * computedInterim.scaleY;

    // Apply the X&Y deltas
    computedInterim.x =
        computedInterim.x + xDelta - computedInterim.anchorX * computedInterim.scaleX;
    computedInterim.y =
        computedInterim.y + yDelta - computedInterim.anchorY * computedInterim.scaleY;

    // Apply the scale to the anchor position
    computedInterim.anchorX = computedInterim.anchorX * computedInterim.scaleX;
    computedInterim.anchorY = computedInterim.anchorY * computedInterim.scaleY;

    return computedInterim;
};
