import { useCallback, useEffect, useRef, useState } from 'react';

interface ITrackbarProps {
    children: any;
    onDrag: (e, i) => any;
    onStart: (e, i) => any;
    onStop: (e, i) => any;
    axis: any;
    grid: any;
}

export const Trackbar = (props: ITrackbarProps) => {
    const { children, onDrag, onStart, onStop, axis, grid } = props;
    const [initialPos, setInitialPos] = useState(null);
    const [dragging, setDragging] = useState(false);
    const trackbarRef = useRef();

    const drag = useCallback(
        (e: MouseEvent) => {
            // If the axis is set, only apply the drag offset on the correct axis

            const offsetPos = getOffsetPos({ x: e.clientX, y: e.clientY });
            let x = axis === 'y' ? 0 : offsetPos.x - initialPos.x;
            let y = axis === 'x' ? 0 : offsetPos.y - initialPos.y;

            // If the grid is set, snap to the grid
            if (grid) {
                if (grid.x) {
                    x = Math.round(x / grid.x) * grid.x;
                }
                if (grid.y) {
                    y = Math.round(y / grid.y) * grid.y;
                }
            }

            const newOffset = {
                x,
                y
            };

            const dragInfo = {
                offsetX: newOffset.x,
                offsetY: newOffset.y
            };

            onDrag(e, dragInfo);
        },
        [dragging]
    );

    const endDrag = useCallback(
        (e: MouseEvent) => {
            setDragging(false);
            setInitialPos(null);
            onStop(e, { offsetX: 0, offsetY: 0 });
        },
        [dragging]
    );

    useEffect(() => {
        if (dragging) {
            window.addEventListener('mousemove', drag);
            window.addEventListener('mouseup', endDrag);
        } else {
            window.removeEventListener('mousemove', drag);
            window.removeEventListener('mouseup', endDrag);
        }
        return () => {
            window.removeEventListener('mousemove', drag);
            window.removeEventListener('mouseup', endDrag);
        };
    }, [dragging, drag, endDrag]);

    const startDrag = (e: React.MouseEvent) => {
        const pos = getOffsetPos({ x: e.clientX, y: e.clientY });
        setInitialPos(pos);
        setDragging(true);
        onStart(e, pos);
    };

    const getOffsetPos = (clientPos) => {
        const trackbar: any = trackbarRef.current;
        if (trackbar) {
            const boundingRect = trackbar.getBoundingClientRect();
            return { x: clientPos.x - boundingRect.left, y: clientPos.y - boundingRect.top };
        }

        return clientPos;
    };

    const absoluteStyle: any = {
        position: 'absolute',
        zIndex: 8000
    };

    return (
        <div
            ref={trackbarRef}
            style={absoluteStyle}
            className='trackbar'
            onMouseDown={startDrag}>
            {children}
        </div>
    );
};
