import * as React from 'react';
import { ASSET_TYPES } from '../../constants/story';
import { Keyframe } from './Keyframe';
import WaveSurfer from 'wavesurfer.js';
import { ICompositionLayer } from '../../constants/snippets';

interface ILayerKeyframeDisplaysProps {
    config: ICompositionLayer;
    scale: number;
    onSelect: (i) => any;
    onDelete: (field, i) => any;
    onJump: (i) => any;
    onMove: (field, i, f) => any;
    onChange: (field, k) => any;
    onDeselect: () => any;
    activeKeyframe: string;
    compositions: any;
    compositionId: string;
    layerSources: any;
    sourceKey: string;
    layerWidth: number;
    timelineWidth: number;
}

export class LayerKeyframeDisplay extends React.Component<ILayerKeyframeDisplaysProps> {
    private visualFields = ['dimensions', 'position', 'anchor', 'scale', 'opacity', 'volume'];

    private audioFields = ['volume'];

    private waveformRef: any;

    private waveSurfer: any;

    constructor(props) {
        super(props);

        this.waveformRef = React.createRef();
    }

    public componentDidMount(): void {
        if (this.props.config.type === ASSET_TYPES.AUDIO) {
            this.waveSurferHandler();
        }
    }

    public componentDidUpdate(prevProps: Readonly<ILayerKeyframeDisplaysProps>): void {
        const {
            layerSources,
            scale,
            config: {
                type,
                effects: { volume }
            }
        } = this.props;

        const {
            layerSources: prevSources,
            scale: prevScale,
            config: {
                effects: { volume: prevVolume }
            }
        } = prevProps;

        if (type === ASSET_TYPES.AUDIO) {
            if (layerSources !== prevSources || volume !== prevVolume) {
                this.waveSurferHandler();
            }

            if (scale !== prevScale) {
                this.updateWaveSurferWidth();
            }
        }
    }

    private waveSurferHandler() {
        const {
            config: { effects }
        } = this.props;

        if (this.waveSurfer) this.waveSurfer.destroy();
        if (this.getAudioAsset() === undefined) return;

        const { url } = this.getAudioAsset();
        const volumeMultiplier: number = effects?.volume ? Number(effects.volume) : 1;

        this.waveSurfer = new WaveSurfer.create({
            container: this.waveformRef.current,
            backend: 'MediaElement',
            height: 22 * volumeMultiplier,
            barHeight: 3 * volumeMultiplier,
            progressColor: '#48b1de',
            responsive: true,
            waveColor: '#48b1de',
            cursorColor: 'transparent'
        });

        this.waveSurfer.load(url);
        this.updateWaveSurferWidth();
        this.waveformRef.current.children[0].style.overflow = 'unset';
    }

    private updateWaveSurferWidth() {
        const {
            compositions,
            compositionId,
            config: { start_frame, end_frame },
            scale
        } = this.props;

        const { duration } = this.getAudioAsset();
        const audioDuration = duration ? duration : null;
        const { rate } = compositions[compositionId];

        const layerDuration = (end_frame - start_frame) / rate;
        const width = (audioDuration / layerDuration) * 100;

        this.waveformRef.current.style.width = `${width}%`;
        this.waveSurfer.zoom(Number(scale));
    }

    private getAudioAsset() {
        const { layerSources, sourceKey } = this.props;
        return layerSources[sourceKey];
    }

    public render() {
        const {
            config,
            scale,
            onSelect,
            onDeselect,
            onChange,
            onDelete,
            onJump,
            onMove,
            activeKeyframe,
            layerWidth,
            timelineWidth
        } = this.props;

        const fields = config.type === ASSET_TYPES.AUDIO ? this.audioFields : this.visualFields;

        const keyFrames = fields.map((field) => {
            const keyframes =
                config.keyframes && config.keyframes[field] ? config.keyframes[field] : [];
            return (
                <KeyframeRow
                    key={field}
                    scale={scale}
                    disableEase={field === 'volume'}
                    onSelect={onSelect}
                    onDeselect={onDeselect}
                    onChange={(k) => onChange(field, k)}
                    onDelete={(i) => onDelete(field, i)}
                    onJump={onJump}
                    onMove={(i, f) => onMove(field, i, f)}
                    activeKeyframe={activeKeyframe}
                    keyframes={keyframes}
                />
            );
        });

        const waveFormStyle: React.CSSProperties = {
            left: layerWidth,
            width: timelineWidth - layerWidth
        };

        const waveForm =
            config.type === ASSET_TYPES.AUDIO ? (
                <>
                    <div
                        className='layer-keyframe-row'
                        key='wave'
                        ref={this.waveformRef}
                    />
                    <div
                        className='waveform-cover'
                        style={waveFormStyle}
                        key='wave-cover'
                    />
                </>
            ) : null;

        const displayStyle: React.CSSProperties =
            config.type === ASSET_TYPES.AUDIO ? { display: 'flex', flexDirection: 'column' } : null;

        return (
            <div
                className='layer-keyframe-display'
                style={displayStyle}>
                {keyFrames}
                {waveForm}
            </div>
        );
    }
}

export const KeyframeRow = ({
    keyframes,
    scale,
    onSelect,
    onChange,
    activeKeyframe,
    onMove,
    onJump,
    onDelete,
    disableEase,
    onDeselect
}) => {
    return (
        <div className='layer-keyframe-row'>
            {keyframes.map((keyframe, i) => {
                const selected = activeKeyframe === keyframe.id;
                return (
                    <Keyframe
                        key={keyframe.id}
                        scale={scale}
                        disableEase={disableEase}
                        selected={selected}
                        onMove={(f) => onMove(i, f)}
                        onSelect={onSelect}
                        onDeselect={onDeselect}
                        onDelete={() => onDelete(i)}
                        onJump={onJump}
                        onChange={onChange}
                        config={keyframe}
                    />
                );
            })}
        </div>
    );
};
