import * as React from 'react';
import {
    TAG_TYPE_OPTIONS,
    TAG_TYPES,
    DEFAULT_TAG_TYPE,
    DEFAULT_TAG_REQUIRED,
    TAG_REQUIRED_OPTIONS,
    VARIABLE_TYPES,
    ASSET_TYPES
} from '../constants/story';
import {
    Button,
    TextField,
    SelectField,
    ControlledList,
    FieldWrapper,
    filterHotkeys,
    updateFilters
} from '@imposium-hub/components';
import { getVariableOptions } from '../util/story';
import { logError } from '../util/notifications';
import { ITag, IVariables } from '../constants/snippets';
import { fields as copy } from '../constants/copy';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import hotkeys from 'hotkeys-js';
import { ICON_PLUS, ICON_FILTER, ICON_WARNING } from '../constants/icons';

interface ITagFieldsProps {
    tags?: ITag[];
    assetType: string;
    variables: IVariables;
    onChange(e): void;
    updateFilters(f): void;
}

interface ITagFieldsState {
    tagRequired: boolean;
    tagType: string;
    tagValue: string;
}

class TagFields extends React.PureComponent<ITagFieldsProps, ITagFieldsState> {
    private evtHandlers: any;

    private btnAdd: JSX.Element;

    constructor(props) {
        super(props);

        this.state = {
            tagType: DEFAULT_TAG_TYPE,
            tagRequired: DEFAULT_TAG_REQUIRED,
            tagValue: ''
        };

        this.evtHandlers = {
            submit: () => this.addTag(),
            inputs: {
                tagValue: (v) => this.updateTagState('tagValue', v),
                tagType: (v) => this.updateTagState('tagType', v),
                tagRequired: (v) => this.updateTagState('tagRequired', v),
                focusValue: (e) => this.tagInputFocus(e),
                blurValue: (e) => this.tagInputBlur(e)
            },
            buttons: {
                addTag: () => this.addTag(),
                filterVar: () => this.filterViaTags(true),
                filterNoVar: () => this.filterViaTags(false)
            },
            removeTag: (t) => this.removeTag(t)
        };

        this.btnAdd = (
            <Button
                color='primary'
                key='btn-add'
                tooltip={copy.tags.tooltipAdd}
                style='subtle'
                onClick={this.evtHandlers.buttons.addTag}>
                {ICON_PLUS}
            </Button>
        );

        hotkeys.filter = (e) => filterHotkeys(e);
    }

    public addTag() {
        const { tags } = this.props;
        const { tagValue, tagRequired, tagType } = this.state;

        if (tagValue === '') {
            logError(copy.tags.emptyError);
        } else {
            this.setState(
                {
                    tagValue: ''
                },
                () => {
                    const tag = {
                        type: tagType,
                        required: tagRequired,
                        value: tagValue
                    };

                    const newTags = [...tags];
                    newTags.push(tag);

                    this.props.onChange(newTags);
                }
            );
        }
    }

    private filterViaTags(useVariableTags) {
        const { tags, variables, assetType } = this.props;

        const filterType =
            assetType === ASSET_TYPES.VIDEO
                ? `${ASSET_TYPES.VIDEO},${ASSET_TYPES.VIDEO_COMPOSITION}`
                : assetType;
        const addTag = (t) => {
            if (!tagStr) {
                tagStr = t;
            } else {
                tagStr += `,${t}`;
            }
        };
        let tagStr;

        for (const tag of tags) {
            if (tag.type === TAG_TYPES.TEXT) {
                addTag(tag.value);
            } else if (tag.type === TAG_TYPES.VARIABLE && useVariableTags) {
                const variable = variables[tag.value];
                if (variable && variable.previewItem && variable.previewItem.src) {
                    addTag(variable.previewItem.src);
                }
            }
        }

        this.props.updateFilters({ name: '', tags: tagStr, type: filterType });
    }

    private removeTag(index) {
        const newTags = [...this.props.tags];
        newTags.splice(index, 1);

        this.props.onChange(newTags);
    }

    public updateTagState(key, value) {
        const state: any = {
            [key]: value
        };

        this.setState(state, () => {
            if (this.state.tagType === TAG_TYPES.VARIABLE && this.state.tagValue !== '') {
                this.addTag();
            }
        });
    }

    private tagInputFocus(e) {
        hotkeys('enter', this.evtHandlers.submit);
    }

    private tagInputBlur(e) {
        hotkeys.unbind('enter', this.evtHandlers.submit);
    }

    public componentWillUnmount() {
        hotkeys.unbind('enter', this.evtHandlers.submit);
    }

    public renderTagValueInput() {
        const { variables } = this.props;
        const { tagType, tagValue } = this.state;

        if (tagType === TAG_TYPE_OPTIONS[0].value) {
            return (
                <TextField
                    value={tagValue}
                    width='calc(100% - 140px)'
                    buttons={this.btnAdd}
                    tooltip={copy.tags.tooltipStatic}
                    onFocus={this.evtHandlers.inputs.focusValue}
                    onBlur={this.evtHandlers.inputs.blurValue}
                    placeholder={copy.tags.value}
                    onChange={this.evtHandlers.inputs.tagValue}
                />
            );
        } else if (tagType === TAG_TYPE_OPTIONS[1].value) {
            return (
                <SelectField
                    value={tagValue}
                    width='calc(100% - 140px)'
                    tooltip={copy.tags.tooltipVariable}
                    options={getVariableOptions(variables, [
                        VARIABLE_TYPES.TEXT,
                        VARIABLE_TYPES.ENUM,
                        VARIABLE_TYPES.NUMBER
                    ])}
                    onChange={this.evtHandlers.inputs.tagValue}
                />
            );
        }
    }

    public renderTags() {
        const { tags, variables } = this.props;
        const tagArray = [];

        for (const tag of tags) {
            tagArray.push(
                <Tag
                    key={`${tag.type}-${tag.value}`}
                    tag={tag}
                    variables={variables}
                />
            );
        }

        return tagArray;
    }

    private renderFilterButtons() {
        const { tags } = this.props;
        if (tags.length > 0) {
            return (
                <div
                    className='tag-buttons'
                    style={{ textAlign: 'right' }}>
                    <Button
                        color='secondary'
                        tooltip={copy.tags.tooltipFilterStatic}
                        onClick={this.evtHandlers.buttons.filterNoVar}>
                        {ICON_FILTER}
                    </Button>
                    <Button
                        color='primary'
                        tooltip={copy.tags.tooltipFilterVariable}
                        onClick={this.evtHandlers.buttons.filterVar}>
                        {ICON_FILTER}
                    </Button>
                </div>
            );
        } else {
            return null;
        }
    }

    public render() {
        const { tagType, tagRequired } = this.state;
        const tagComponents = this.renderTags();
        return (
            <>
                <FieldWrapper
                    label={copy.tags.add}
                    tooltip={copy.tags.tooltipAdd}>
                    <SelectField
                        value={tagType}
                        tooltip={copy.tags.tooltipType}
                        options={TAG_TYPE_OPTIONS}
                        width={70}
                        onChange={this.evtHandlers.inputs.tagType}
                    />

                    <SelectField
                        value={tagRequired}
                        tooltip={copy.tags.tooltipRequired}
                        options={TAG_REQUIRED_OPTIONS}
                        width={70}
                        onChange={this.evtHandlers.inputs.tagRequired}
                    />

                    {this.renderTagValueInput()}
                </FieldWrapper>

                <FieldWrapper
                    label='Tags'
                    tooltip={copy.tags.tooltipTags}>
                    <ControlledList
                        items={tagComponents}
                        removable={true}
                        onRemove={this.evtHandlers.removeTag}
                    />
                    {this.renderFilterButtons()}
                </FieldWrapper>
            </>
        );
    }
}

const Tag = ({ tag, variables }) => {
    const { type, required, value } = tag;

    const previewFound = (variable) => {
        if (
            variable &&
            variable.previewItem &&
            variable.previewItem.src !== null &&
            variable.previewItem.src !== undefined
        ) {
            return true;
        }
    };

    const tagType = type === TAG_TYPES.TEXT ? copy.global.static : copy.global.variable;
    const warning =
        tagType === copy.global.variable && !previewFound(variables[value]) ? (
            <Button
                customStyles={{
                    color: `#8F7B25`,
                    marginLeft: '5px',
                    display: 'inline-block',
                    background: 'transparent',
                    position: 'relative',
                    bottom: '2px',
                    border: 'none'
                }}
                tooltip={`No preview value has been set for variable ${value}`}>
                {ICON_WARNING}
            </Button>
        ) : null;

    return (
        <div className={`tag ${type}`}>
            <div className='type'>{tagType}</div>
            <div className='required'>{required ? copy.global.required : copy.global.optional}</div>
            <div className='value'>{value}</div>
            {warning}
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ updateFilters }, dispatch);
};

const mapStateToProps = (state): any => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TagFields);
